import React,{ useState, useEffect } from 'react';
import {Link, useLocation} from "react-router-dom"
import ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios'

import "./css/prism.css"
import Header from "../header"
import { FaTrash } from "react-icons/fa"
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const Falcons = () => {
    const [falcons, setFalcons] = useState([]) 
    const location = useLocation()
    const account = location.state['account']
    const prev_val = {}
    const [sort_column, setSortColumn] = useState('falcon')
    const [sort_order, setSortOrder] = useState(true)
    const [filter, setFilter] = useState("")
    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem('walmates-token')
        axios.get("https://walmates.com/api/prism/falcon/get?user=" + account.name, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            let data = result.data
            data.map((d) => {d.visible = true})
            console.log("Falcons Received", data)
            setFalcons(data)
        })
        .catch(err => {
          console.error(err)
          navigate("/login", {replace:true});
        });
    }, []);

    useEffect(()=> {
        let data = [...falcons]
        data = data.sort((a, b) => { 
            if (sort_order == true) {
                return a[sort_column] > b[sort_column] ? -1 : 1
            } else {
                return a[sort_column] > b[sort_column] ? 1 : -1
            }
        })
        setFalcons(data)

    }, [sort_column, sort_order]);

    useEffect(()=> {
        let data = [...falcons]
        data.map( f => 
            { f.visible = filter == "" || f.name.toUpperCase().includes(filter) || f.algo.toUpperCase().includes(filter) || f.ticker.toUpperCase().includes(filter)
            if (f.visible == false && f.sector != null) {
                f.visible = f.sector.includes(filter)
            }}
        )
        setFalcons(data)

    }, [filter]);

    const capturePrevious = (falcon) => {
        prev_val.allow_buy = falcon ? falcon.allow_buy : 0
        prev_val.allow_sell = falcon ? falcon.allow_sell :0
    };

    const restorePrevious = (f) => {
        console.log("Restoring Previous value for falcon", f.name)
        setFalcons(prevState =>
            prevState.map(falcon => {
                if (falcon.name == f.name) {
                    return {
                        ...falcon,
                        allow_buy:prev_val.allow_buy,
                        allow_sell:prev_val.allow_sell,
                    }
                }
                return falcon;
            })
        )
    }

    const handleFalconEdit = (f) => {
            
        setFalcons(prevState =>
            prevState.map(falcon => {
                if (falcon.name == f.name) {
                    return {
                        ...falcon,
                        algo: f.algo,
                        allow_sell: f.allow_sell,
                        allow_buy: f.allow_buy,
                        shard: f.shard
                    }
                }
                return falcon;
            })
        )
    }

    const handleFalconAdd = (newFalcon, resetCallback) => {
        let token = localStorage.getItem('walmates-token')
        axios.get("https://walmates.com/api/prism/falcon/add?user=" + account.name +
            "&name=" + newFalcon.name + 
            "&ticker=" + newFalcon.ticker + 
            "&algo=" + newFalcon.algo + 
            "&allow_buy=" + newFalcon.allow_buy + 
            "&allow_sell=" + newFalcon.allow_sell + 
            "&shard=" + newFalcon.shard, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            let data = result.data
            setFalcons([...falcons, newFalcon])
            resetCallback()
        });
    }


    const handleFalconDelete = (f) => {
        let token = localStorage.getItem('walmates-token')
        axios.get("https://walmates.com/api/prism/falcon/delete?user=" + account.name +
            "&name=" + f.name, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            let data = result.data
            console.log("Falcon:" + f.name + " deleted!");
            setFalcons([
                ...falcons.filter(falcon => {
                    return falcon.name != f.name;
                }),
            ])
        });
    }

    const updateFalconField = (field, value, f) => {
        console.log("Field:", field, " value:", value)
        if(field != "algo" && isNaN(value) || value < 0) {
            restorePrevious(f)
            return
        }
        let token = localStorage.getItem('walmates-token')
        axios.get("https://walmates.com/api/prism/falcon/edit?user=" + account.name +
            "&name=" + f.name + 
            "&field=" + field + 
            "&value=" + value, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
         let data = result.data
          console.log(f.name + " field:" + field + " value:" + value + " updated");
        })
    }

    const handleUpdateDone = (e,field, f) => {
        if (e.key == "Enter") {
            updateFalconField(field, e.target.value, f)
        }
    }

    const onClickHandler = (col) => {
        setSortOrder(!sort_order)
        setSortColumn(col)
    }

    let idx = 0
    return (
        <>
        <Header/>

        <AddFalcon account={account} handleFalconAdd={handleFalconAdd}/>

        <div className="falcon_table">
        <td style={{width:"30em", marginBottom: "2px"}}> 
            <input
            type="text"
            className="falcon_text"
            placeholder="Search:"
            onChange={(e) => {
                setFilter(e.target.value.trim().toUpperCase())
            }}
            />
        </td>
        <table>
        <FalconHeader onClickHandler={onClickHandler}/>
        {
            falcons.map(f => (f.visible == true) ? 
                <tr>
                <td>{idx += 1}</td>
                <td> {f.sector ? f.sector : "--"}</td>
                <td className="falcon_name"> {f.name} </td>
                <td className="falcon_name"> 
                 <Link to="/prism/pending" state={{account: account, falcon_name: f.name, ticker: f.ticker}}>{f.ticker.replace('-', '')}</Link>
                </td>
                <td>{f.level? f.level.toFixed(2): 0} %</td>
                <td>{f.lowest? f.lowest.toFixed(2): 0}</td>
                <td style={{background:"#eeffee"}}>{f.price? f.price.toFixed(4): 0}</td>
                <td>{f.highest ? f.highest.toFixed(2): 0}</td>
                <td>{f.cash_allocated ? f.cash_allocated: 0}</td>
                <td>{f.investment? f.investment.toFixed(2): 0}</td>
                <td>{f.turnover? f.turnover.toFixed(2): 0}</td>
                <td>{f.holding? f.holding.toFixed(2): 0}</td>
                <td>{f.value? f.value.toFixed(2): 0}</td>
                <td style={{color:f.delta >= 0? "green": "red"}} >{f.delta? f.delta.toFixed(2): 0}</td>
                <td style={{color:f.margin >= 0? "green" : "red"}} >{f.margin? f.margin.toFixed(2): 0} %</td>
                <td>{f.profit? f.profit.toFixed(4): 0}</td>
                </tr>
            : null)
        }
        </table>
        </div>
        </>
    )
}
export default Falcons

const FalconHeader = (props) => {

    return (
        <>
        <tr>
        <th>No</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this,"sector")}>Sector</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this,"name")}>Falcon</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this,"ticker")}>Ticker</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "level")}> Down</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "lowest")}> Low</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "price")}> Price</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "highest")}> High</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "allocation")}>Allocation</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "investment")}>Investment</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "turnover")}> Turnover</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "holding")}> Holding</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "value")}> Value</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "delta")}> Delta</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "margin")}> Margin</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "profit")}> Profit</th>
        </tr>
        </>
    )
    
}

const AddFalcon = (props) => {

    const [add_disabled, setAddDisabled] = useState(false)
    const [newFalcon, setNewFalcon] = useState({
        name: "",
        ticker: "",
        algo: "pyramid",
        allow_sell: 1,
        allow_buy: 1,
        shard:0
    })


    const resetNewAdd = () => {
        setNewFalcon({
            name: "",
            ticker: "",
            algo: "pyramid",
            allow_sell: 1,
            allow_buy: 1,
            shard:0
        })
        setAddDisabled(false)
    }

    const handleNewFalconEdit = (nf) => {
        setNewFalcon(prev => {
            return {
                ...prev,
                    name: nf.name,
                    ticker: nf.ticker,
                    algo: nf.algo,
                    allow_buy: nf.allow_buy,
                    allow_sell: nf.allow_sell,
                    shard : nf.shard
            }
        })
    }

    return (
        <>
        <div className="falcon_table">
        <table>
        <tr>
        <th>Name</th>
        <th>Ticker</th>
        <th>Algo</th>
        <th>Buy</th>
        <th>Sell</th>
        <th>Shard</th>
        <th>Add</th>
        </tr>
        <tr>
        <td style={{background: add_disabled == false ? "white" : "#fafafa"}}>
            <input
                type="text"
                className="falcon_text"
                value={newFalcon.name}
                disabled = {add_disabled}
                placeholder="Falcon:"
                onChange={(e) => {
                    newFalcon.name = e.target.value
                    handleNewFalconEdit(newFalcon)
                }}
                onKeyDown={(e) => {}}
                onBlur={(e) => {}}
            />
        </td>
        <td style={{background: add_disabled == false ? "white" : "#fafafa"}}>
            <input
                type="text"
                className="falcon_text"
                value={newFalcon.ticker}
                placeholder="Ticker:"
                disabled = {add_disabled}
                onChange={(e) => {
                    newFalcon.ticker = e.target.value
                    handleNewFalconEdit(newFalcon)
                }}
                onKeyDown={(e) => {}}
                onBlur={(e) => {}}
            />
        </td>
        <td style={{background: add_disabled == false ? "white" : "#fafafa"}}>
            <input
                type="text"
                className="falcon_text"
                value={newFalcon.algo}
                disabled = {add_disabled}
                onChange={(e) => {
                    newFalcon.algo = e.target.value
                    handleNewFalconEdit(newFalcon)
                }}
                onKeyDown={(e) => {}}
                onBlur={(e) => {}}
            />
        </td>

        <td style={{background: add_disabled == false ? "white" : "#fafafa"}}>
            <input
                type="text"
                className="falcon_text"
                disabled = {add_disabled}
                value={newFalcon.allow_buy}
                onChange={(e) => {
                    newFalcon.allow_buy = e.target.value
                    handleNewFalconEdit(newFalcon)
                }}
                onKeyDown={(e) => {}}
                onBlur={(e) => {}}
            />
        </td>

        <td style={{background: add_disabled == false ? "white" : "#fafafa"}}>
            <input
                type="text"
                className="falcon_text"
                value={newFalcon.allow_sell}
                disabled = {add_disabled}
                onChange={(e) => {
                    newFalcon.allow_sell = e.target.value
                    handleNewFalconEdit(newFalcon)
                }}
                onKeyDown={(e) => {}}
                onBlur={(e) => {}}
            />
        </td>

        <td style={{background: add_disabled == false ? "white" : "#fafafa"}}>
                <input
                    type="text"
                    className="falcon_text"
                    value={newFalcon.shard}
                    disabled = {add_disabled}
                    onChange={(e) => {
                        newFalcon.shard = e.target.value
                        handleNewFalconEdit(newFalcon)
                    }}
                    onKeyDown={(e) => {}}
                    onBlur={(e) => {}}
                />
        </td>

        <td style={{background: add_disabled == false ? "white" : "#fafafa"}}>
        <button 
        onClick={() => {
            if(add_disabled == false) {
                setAddDisabled(true)
                props.handleFalconAdd(newFalcon, resetNewAdd);
            }
            else {
                setAddDisabled(false)
            }
        }}>
        <AddCircleOutlineIcon style={{color: add_disabled == true ? "grey" : "green", fontSize:"20px"}} />
        </button>
        </td>
        </tr>
        </table>
        </div>
        </>
    )
}
