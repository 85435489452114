import React,{ useState, useEffect } from 'react';
import {Link, useLocation} from "react-router-dom"
import ReactDOM from 'react-dom';

import "./css/prism.css"
import Header from "../header"

const SellPending = () => {
    const [result, setResult] = useState({falcons:[]}) 
    const location = useLocation()
    const account = location.state['account']

    useEffect(() => {
        let query = "https://walmates.com/api/prism/sell_pending/get?user=" + account.name
        console.log("requesting:", query)
        fetch(query)
        .then(result=>result.json())
        .then(data => {
            console.log(data)
            setResult(data)
         })
        .catch(err => {
            console.log("Received error", err);
        })
    }, []);

    return (
        <>
        <Header/>
        <div className="falcon_table">
        <h1> Sell Pending </h1>
        {
            result.falcons.map(f => (f.quantity != 0) ?
                <>
                <table style={{width:"100%", marginTop:"3em"}}>
                <tr>
                <th>Falcon</th>
                <th>Ticker</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Investment</th>
                <th>Value</th>
                <th>Make</th>
                </tr>
                <tr>
                <td>{f.falcon}</td>
                <td>{f.ticker}</td>
                <td>{f.price.toFixed(2)}</td>
                <td>{f.quantity.toFixed(2)}</td>
                <td>{f.investment.toFixed(2)}</td>
                <td>{f.value.toFixed(2)}</td>
                <td>$ {(f.value - f.investment).toFixed(2)}</td>
                </tr>
                </table>

                <table style={{width:"100%"}}>
                <tr>
                <th>OrderId</th>
                <th>Buy Price</th>
                <th>Sell Price</th>
                <th>Invest</th>
                <th>Value</th>
                <th>Profit</th>
                <th>Margin</th>
                </tr>
                { f.orders.map(o => 
                    <tr style={{background: (f.price > o.sell_price)? "#eeffe6" :"#ffffea"}}>
                    <td>{o.order_id}</td>
                    <td>{o.buy_price.toFixed(2)}</td>
                    <td>{o.sell_price.toFixed(2)}</td>
                    <td>{(o.investment).toFixed(2)}</td>
                    <td>{(o.value).toFixed(2)}</td>
                    <td style={{color:"green"}}>{(o.value - o.investment).toFixed(2)}</td>
                    <td style={{color:"green"}}>{((o.value - o.investment)/o.investment * 100).toFixed(2)} %</td>
                    </tr>
                )}
                </table> 
                </>
            : null)
        }
        </div>
        </>
    )
}
export default SellPending

