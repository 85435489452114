import React,{ useState, useEffect } from 'react';
import {Link, useLocation} from "react-router-dom"
import ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios'

import "./css/prism.css"
import Header from "../header"
import { FaTrash } from "react-icons/fa"
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const Capital = () => {
    const [capitals, setCapitals] = useState([]) 
    const [cash_summary, setCashSummary] = useState({})
    const [account_cash_allocated, setAccountCashAllocated] = useState(0)
    const [depositable_cash, setDepositableCash] = useState(0)
    const location = useLocation()
    const account = location.state['account']
    const prev_val = {}
    const [sort_column, setSortColumn] = useState('available')
    const [sort_order, setSortOrder] = useState(true)
    const [filter, setFilter] = useState("")
    const [account_cash_balance, setAccountCashBalance] = useState(0)
    const navigate = useNavigate();

    useEffect(()=> {
        cash_summary['cash_allocated'] = 0
        cash_summary['cash_used'] = 0
        cash_summary['cash_available'] = 0
        capitals.map( c => cash_summary.cash_allocated += (c.cash_allocated) ? parseFloat(c.cash_allocated) : 0)
        capitals.map( c => cash_summary.cash_used += (c.cash_used) ? parseFloat(c.cash_used) : 0)
        capitals.map( c => cash_summary.cash_available += (c.cash_available) ? parseFloat(c.cash_available) : 0)
        cash_summary['cash_profit'] = cash_summary.cash_available + cash_summary.cash_used - cash_summary.cash_allocated
        setCashSummary(cash_summary)
        console.log("Cash balance: ", account_cash_balance, " Available:", cash_summary.cash_available)
        setDepositableCash(account_cash_balance - cash_summary.cash_available - (account_cash_allocated - cash_summary.cash_allocated))
    }, [capitals],
    );

    useEffect(() => {
        let token = localStorage.getItem('walmates-token')
        axios.get("https://walmates.com/api/prism/capital/get?user=" + account.name, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            let data = result.data
            console.log("Capitals Received", data)
            data.capitals.map((c) => {
                c.visible = true
                c.cash_qty = c.cash_available > 0 ? parseInt(c.cash_available / c.price) : 0;
                c.cash_profit = (c.cash_available + c.cash_used - c.cash_allocated)
                c.cash_margin = c.cash_allocated > 0 ? (c.cash_profit/c.cash_allocated) * 100 : 0
            })
            setAccountCashAllocated(data.account_cash_allocated)
            setAccountCashBalance(data['account_balance'])
            setCapitals(data.capitals)
        })
        .catch(err => {
          console.error(err)
          navigate("/login", {replace:true});
        });
    }, []);

    useEffect(()=> {
        let data = [...capitals]
        data = data.sort((a, b) => { 
            if (sort_order == true) {
                return a[sort_column] > b[sort_column] ? -1 : 1
            } else {
                return a[sort_column] > b[sort_column] ? 1 : -1
            }
        })
        setCapitals(data)

    }, [sort_column, sort_order]);

    useEffect(()=> {
        let data = [...capitals]
        data.map( c => 
            { c.visible = filter == "" || c.falcon.toUpperCase().includes(filter) }
        )
        setCapitals(data)

    }, [filter]);

    const onClickHandler = (col) => {
        setSortOrder(!sort_order)
        setSortColumn(col)
    }

    const handleDepositCashToAccount = (amount) => {
        console.log("Add Cash:", amount)
        if (amount == 0) {
            return
        }
        let token = localStorage.getItem('walmates-token')
        axios.get("https://walmates.com/api/prism/account/depositcash?user=" + account.name +
            "&amount=" + amount, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            let data = result.data
            console.log("Deposit Cash Return Value:", data)
            if (data.cash_allocated != null) {
                setAccountCashAllocated(data.cash_available)
            }
        });
    }

    const handleAllocateCash = (capital, amount) => {
        if (amount == 0) {
            return
        }
        console.log("Account Cash:", account_cash_allocated, " Allocated:", cash_summary.cash_allocated, " Amount:", amount, " total:", cash_summary.allocated + amount);
        let total = parseInt(cash_summary.cash_allocated) + parseInt(amount)
        if (account_cash_allocated < total) {
            console.log("ERROR: account deposit(" + account_cash_allocated + ") doesn't have enough to allocate: " + total)
            return
        }

        let token = localStorage.getItem('walmates-token')
        axios.get("https://walmates.com/api/prism/capital/allocate_cash?user=" + account.name +
            "&amount=" + amount + 
            "&falcon=" + capital.falcon, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function(result) {
            let data = result.data
            console.log("AllocateCashReturn Value:", data)
            if (data.capital != null) {
                capital = data.capital
                const newCapitals = capitals.map(c => {
                    if (c.falcon == capital.falcon) {
                        c.cash_used = capital.cash_used
                        c.cash_available = capital.cash_available
                        c.cash_allocated = capital.cash_allocated
                        c.cash_qty = c.cash_available > 0 ? parseInt(c.cash_available / c.price) : 0;
                        c.cash_profit = c.cash_used + c.cash_available - c.cash_allocated
                        c.cash_margin = c.cash_profit/c.cash_allocated * 100
                    }
                    return c
                })
                setCapitals(newCapitals)
            }
        });
    }

    let idx = 0
    return (
        <>
        <Header/>
        <div className="falcon_table">
        <h2 style={{marginTop:"1em"}}> Capital - {account.name} </h2>
        </div>

        <div className="spectrum_table">
        <table>
        <tr>
        <th>Cash Used</th>
        <th>Cash Available</th>
        <th>Cash Profit</th>
        <th>Cash Allocation <br/> Used</th>
        <th>Cash Allocated</th>
        <th>Broker <br/> Cash Balance</th>
        <th>Deposit Cash <br/> [ {depositable_cash} ] </th>
        </tr>

        <tr>
        <td>{cash_summary.cash_used? cash_summary.cash_used.toFixed(2): 0}</td>
        <td>{cash_summary.cash_available? cash_summary.cash_available.toFixed(2): 0}</td>
        <td style={{color:"green", fontWeight:"bold"}} >{cash_summary.cash_profit? cash_summary.cash_profit.toFixed(2): 0}</td>
        <td>{cash_summary.cash_allocated? cash_summary.cash_allocated.toFixed(2): 0}</td>
        <td>{account_cash_allocated}</td>
        <td>{account_cash_balance}</td>
        <td style={{maxWidth:"5em"}}> 
            <input
            type="number" pattern="^-?[0-9]\d*\.?\d*$" 
            className="falcon_text"
            placeholder={0}
            style={{border:"1px solid #eee"}}
            onBlur={(e) => {
                handleDepositCashToAccount(e.target.value)
                e.target.value = 0
            }}
        />
        </td>
        </tr>
        </table>
        </div>

        <div className="spectrum_table">
        <td style={{width:"30em", marginBottom: "2px"}}> 
            <input
            type="text"
            className="falcon_text"
            placeholder="Search:"
            onChange={(e) => {
                setFilter(e.target.value.trim().toUpperCase())
            }}
            />
        </td>
        <table>
        <tr>
        <th>No</th>
        <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"falcon")}>Falcon</th>
        <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"price")}>Price</th>
        <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"cash_used")}>Cash Used</th>
        <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"cash_available")}>Cash Available</th>
        <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"cash_qty")}>Cash Qty</th>
        <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"cash_allocated")}>Cash Allocated</th>
        <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"cash_profit")}>Cash Profit</th>
        <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"cash_margin")}>Cash Margin %</th>
        <th >Allocate Cash <br/>[{account_cash_allocated - cash_summary.cash_allocated}]</th>
        </tr>
        {
            capitals.map(c => (c.visible == true) ? 
                <tr>
                <td>{idx += 1}</td>
                <td className="falcon_name"> 
                 <Link to="/prism/pending" state={{account: account, falcon_name: c.falcon, ticker: c.falcon}}>{c.falcon.replace('-', '')}</Link>
                </td>
                <td style={{background:"#eeffee"}}> {c.price.toFixed(4)} </td>
                <td>{c.cash_used.toFixed(2)}</td>
                <td style={{color: (c.cash_allocated && (c.cash_available/c.cash_allocated * 100) <= 20) ? "red" : "green"}}>{c.cash_available.toFixed(2)}</td>
                <td>{c.cash_qty.toFixed(2)}</td>
                <td>{c.cash_allocated.toFixed(2)}</td>
                <td>{c.cash_profit.toFixed(2)}</td>
                <td>{c.cash_margin.toFixed(2)} %</td>
                <td style={{maxWidth:"4em"}}> 
                    <input
                    type="number" pattern="^-?[0-9]\d*\.?\d*$" 
                    className="falcon_text"
                    placeholder={0}
                    style={{border:"1px solid #eee"}}
                    onBlur={(e) => {
                        handleAllocateCash(c, e.target.value)
                        e.target.value = 0
                    }}
                />
                </td>
                </tr>
            : null)
        }

        </table>
        </div>
        </>
    )
}
export default Capital
