import React,{ useState, useEffect } from 'react';
import {Link, useLocation} from "react-router-dom"
import ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios'

import "./css/prism.css"
import Header from "../header"

const Configs = () => {
    const [configs, setConfigs] = useState([]) 
    const location = useLocation()
    const account = location.state['account']
    const [sort_column, setSortColumn] = useState('falcon')
    const [sort_order, setSortOrder] = useState(true)
    const [filter, setFilter] = useState("")
    const navigate = useNavigate();

    useEffect(() => {
        console.log("Fatching Configs")
        let token = localStorage.getItem('walmates-token')
        axios.get("https://walmates.com/api/prism/falcon/get_config?user=" + account.name, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            let data = result.data
            data.map((d) => {d.visible = true})
            console.log("Configs Received", data)
            setConfigs(data)
        })
        .catch(err => {
          console.error(err)
          navigate("/login", {replace:true});
        });
    }, []);

    useEffect(()=> {
        let data = [...configs]
        data = data.sort((a, b) => { 
            if (sort_order == true) {
                return a[sort_column] > b[sort_column] ? -1 : 1
            } else {
                return a[sort_column] > b[sort_column] ? 1 : -1
            }
        })
        setConfigs(data)

    }, [sort_column, sort_order]);

    useEffect(()=> {
        let data = [...configs]
        data.map( f => 
            { f.visible = filter == "" || f.falcon.toUpperCase().includes(filter) || f.algo.toUpperCase().includes(filter) || f.ticker.toUpperCase().includes(filter)
            }
        )
        setConfigs(data)

    }, [filter]);


    const onClickHandler = (col) => {
        setSortOrder(!sort_order)
        setSortColumn(col)
    }

    const handleConfigEdit= (f) => {
            
        setConfigs(configs =>
            configs.map(cfg => {
                if (cfg.falcon == f.falcon) {
                    return f
                }
                return cfg;
            })
        )
    }

    const updateConfigField = (field, value, f) => {
        console.log("Falcon Config Update: Field:", field, " value:", value)
        module = "";
        if(field == "algo" || field == 'allow_buy' || field == 'allow_sell' || field == 'shard') {
            module = "falcon"
        } else if(field == "seed_amount" || field == "growth" || field == 'buy_margin' || field == "sell_margin") {
            module = "config"
        }
        if(module == "") {
            console.log("Invalid field and value");
            return
        }
        let token = localStorage.getItem('walmates-token')
        axios.get("https://walmates.com/api/prism/" + module + "/edit?user=" + account.name +
            "&name=" + f.falcon + 
            "&field=" + field + 
            "&value=" + value, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            let data = result.data
            console.log(f.name + " field:" + field + " value:" + value + " updated");
        })
    }

    const handleUpdateDone = (e,field, f) => {
        if (e.key == "Enter") {
            updateConfigField(field, e.target.value, f)
        }
    }



    let idx = 0
    return (
        <>
        <Header/>

        <div className="falcon_table">
        <h2> Falcon Config: { account.name }</h2>
        <td style={{width:"30em", marginBottom: "2px"}}> 
            <input
            type="text"
            className="falcon_text"
            placeholder="Search:"
            onChange={(e) => {
                setFilter(e.target.value.trim().toUpperCase())
            }}
            />
        </td>
        <table>
        <ConfigHeader onClickHandler={onClickHandler}/>
        {
            configs.map(f => (f.visible == true) ? 
                <tr>
                <td>{idx += 1}</td>
                <td> {f.falcon} </td>
                <td> {f.ticker} </td>
                <td style={{maxWidth:"6em"}}>  
                    <input
                    type="text"
                    className="falcon_text"
                    value={f.algo}
                    onChange={(e) => {
                        f.algo = e.target.value
                        handleConfigEdit(f)
                    }}
                    onKeyDown={(e) => {handleUpdateDone(e, "algo", f)}}
                    onBlur={(e) => {updateConfigField("algo", e.target.value, f)}}
                />
                </td>
                <td style={{maxWidth:"2em"}}>  
                    <input
                        type="text"
                        className="falcon_text"
                        value={f.shard}
                        onChange={(e) => {
                            f.shard = e.target.value
                            handleConfigEdit(f)
                        }}
                        onKeyDown={(e) => {handleUpdateDone(e,"shard", f)}}
                        onBlur={(e) => {updateConfigField("shard", e.target.value, f)}}
                    />
                </td>
                <td style={{maxWidth:"2em"}}>  
                    <input
                        type="text"
                        className="falcon_text"
                        value={f.allow_buy}
                        onChange={(e) => {
                            f.allow_buy = e.target.value
                            handleConfigEdit(f)
                        }}
                        onKeyDown={(e) => {handleUpdateDone(e,"allow_buy", f)}}
                        onBlur={(e) => {updateConfigField("allow_buy", e.target.value, f)}}
                    />
                </td>
                <td style={{maxWidth:"2em"}}>  
                    <input
                        type="text"
                        className="falcon_text"
                        value={f.allow_sell}
                        onChange={(e) => {
                            f.allow_sell = e.target.value
                            handleConfigEdit(f)
                        }}
                        onKeyDown={(e) => {handleUpdateDone(e,"allow_sell", f)}}
                        onBlur={(e) => {updateConfigField("allow_sell", e.target.value, f)}}
                    />
                </td>
                <td style={{maxWidth:"2em"}}>  
                    <input
                        type="text"
                        className="falcon_text"
                        value={f.seed_amount}
                        onChange={(e) => {
                            f.seed_amount = e.target.value
                            handleConfigEdit(f)
                        }}
                        onKeyDown={(e) => {handleUpdateDone(e,"seed_amount", f)}}
                        onBlur={(e) => {updateConfigField("seed_amount", e.target.value, f)}}
                    />
                </td>
                <td style={{maxWidth:"2em"}}>  
                    <input
                        type="text"
                        className="falcon_text"
                        value={f.growth}
                        onChange={(e) => {
                            f.growth = e.target.value
                            handleConfigEdit(f)
                        }}
                        onKeyDown={(e) => {handleUpdateDone(e,"growth", f)}}
                        onBlur={(e) => {updateConfigField("growth", e.target.value, f)}}
                    />
                </td>
                <td style={{maxWidth:"2em"}}>  
                    <input
                        type="text"
                        className="falcon_text"
                        value={f.buy_margin}
                        onChange={(e) => {
                            f.buy_margin = e.target.value
                            handleConfigEdit(f)
                        }}
                        onKeyDown={(e) => {handleUpdateDone(e,"buy_margin", f)}}
                        onBlur={(e) => {updateConfigField("buy_margin", e.target.value, f)}}
                    />
                </td>
                <td style={{maxWidth:"2em"}}>  
                    <input
                        type="text"
                        className="falcon_text"
                        value={f.sell_margin}
                        onChange={(e) => {
                            f.sell_margin = e.target.value
                            handleConfigEdit(f)
                        }}
                        onKeyDown={(e) => {handleUpdateDone(e,"sell_margin", f)}}
                        onBlur={(e) => {updateConfigField("sell_margin", e.target.value, f)}}
                    />
                </td>
            </tr>
            : null)
        }
        </table>
        </div>
        </>
    )
}
export default Configs

const ConfigHeader = (props) => {

    return (
        <>
        <tr>
        <th>No</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this,"falcon")}>Falcon</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this,"ticker")}>Ticker</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this,"algo")}>Algo</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "shard")}> Shard</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "allow_buy")}>Buy</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "allow_sell")}>Sell</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "seed_amount")}> Seed </th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "growth")}> Growth </th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "buy_margin")}>Buy Margin</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "sell_margin")}>Sell Margin</th>
        </tr>
        </>
    )
}
