import React,{ useState, useEffect } from 'react';
import {Link, useLocation} from "react-router-dom"
import ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios'

import "./css/prism.css"
import Header from "../header"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"

const Sales = () => {
    const [sales, setSales] = useState([]) 
    const location = useLocation()
    const [startDate, setStartDate] = useState(location.state['start_date'])
    const [endDate, setEndDate] = useState(location.state['end_date'])
    const falcon_name = location.state['falcon_name']
    const account = location.state['account']
    const trading_ticker = location.state['ticker'].replace('-', '')
    const [net_profit, setNetProfit] = useState(0)
    const navigate = useNavigate();

    const convertTime = (timestamp) => {
      let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(timestamp * 1000)
      return date
    }

    const getGap = (st, bt) => {
        if (st == null || bt == null) {
            return "--"
        }
        let gap = st - bt
        if(gap > 3600 * 24) {
            return '' + (gap/(3600 * 24)).toFixed(0) + " days"
        }
        else if (gap > 3600) {
            return '' + (gap/(3600)).toFixed(0) + " hours"
        }
        else if (gap > 60) {
            return '' + (gap/(60)).toFixed(0) + " mins"
        }
        return '' + gap.toFixed(0) + "secs"
    }

    useEffect(() => {
        startDate.setHours(0, 0, 0)
        let start_time = parseInt(startDate.getTime()/1000)
        endDate.setHours(0, 0, 0)
        let end_time = parseInt(endDate.getTime()/1000) + 24 * 60 * 60
        let profit = 0
        let token = localStorage.getItem('walmates-token')
        axios.get("https://walmates.com/api/prism/sales/get?user=" + account.name + "&falcon=" + falcon_name + "&start_time=" + start_time + " &end_time=" + end_time, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            let data = result.data
            console.log("sales received:")
            data.map(p => { profit += p.profit })
            setSales(data)
            setNetProfit(profit)
        })
        .catch(err => {
          console.error(err)
          navigate("/login", {replace:true});
        });
    }, [startDate, endDate]);

    let idx = 0
    return (
        <>
        <Header/>
        <h2 className="falcon_row"> {falcon_name}: $ {net_profit.toFixed(2)} </h2>
        <div className="falcon_row">
        <div className="search_bar">
        <div className="search_item">
        <DatePicker selected={startDate} onChange={(date:Date)=>setStartDate(date)}/>
        </div>
        <div className="search_item">
        <DatePicker selected={endDate} onChange={(date:Date)=>setEndDate(date)}/>
        </div>
        </div>
        </div>

        <div className="spectrum_table">
        <table>
          <tr>
            <th>No</th>
            <th>Buy  Time</th>
            <th>Sell Time</th>
            <th>Time Gap</th>
            <th>Buy  Price</th>
            <th>Sell Price</th>
            <th>Buy  Value</th>
            <th>Sell Value</th>
            <th>Gain</th>
            <th>Gain%</th>
          </tr>

         {
         sales.map(p => (
            <tr>
              <td> {idx += 1} </td>
              <td> {convertTime(p.buy_timestamp)}</td>
              <td> {convertTime(p.sell_timestamp)}</td>
              <td> {p.state == "complete" ? getGap(p.sell_timestamp, p.buy_timestamp) : "--"}</td>
              <td> {p.buy_price.toFixed(4)}</td>
              <td> {p.state == "complete" ? p.sell_price.toFixed(4) : "--"}</td>
              <td> {p.buy_value.toFixed(4)}</td>
              <td> {p.state == "complete" ? p.sell_value.toFixed(4) : "--"}</td>
              <td> {p.state == "complete" ? (p.sell_value - p.buy_value).toFixed(2) : "--"}</td>
              <td> {p.state == "complete" ? ((p.sell_value - p.buy_value)/p.buy_value * 100).toFixed(2) : "--" } %</td>
            </tr>
         ))}
        </table>
        </div> 
        </>
    )
}
export default Sales
