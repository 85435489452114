import React,{ useState, useEffect } from 'react';
import {Link, useLocation} from "react-router-dom"
import ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios'
//import { MarketOverview } from "react-ts-tradingview-widgets";
//import { StockHeatmap } from "react-ts-tradingview-widgets";
//import { MarketData } from "react-ts-tradingview-widgets";

import "./css/prism.css"
import Header from "../header"

const Account = () => {

    const [accounts, setAccounts] = useState([])
    const [total_investment, setTotalInvestment] = useState(0)
    const [summary, setSummary] = useState({})
    const [total_value, setTotalValue] = useState(0)
    const [total_turnover, setTotalTurnover] = useState(0)
    const [total_profit, setTotalProfit] = useState(0)
    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem('walmates-token')
        axios.get("https://walmates.com/api/prism/account/get", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            console.log("Data:", result.data)
            setAccounts(result.data)
        })
        .catch(err => {
          console.error(err)
          navigate("/login", {replace:true});
        });
    }, []);

    useEffect(()=> {
        let investment = 0
        let value = 0
        let profit = 0
        let turnover = 0
        let holding = 0
        accounts.map(a => 
        { 
            if(!a.broker.includes('paper') && a.broker != 'simbroker') {
                investment += a.investment
                holding += a.holding
                turnover += a.turnover
                value += a.value
                profit += a.profit
            }
        })
        let delta = value - holding
        let margin = (holding == 0)? 0 : (delta/holding * 100)
        let summary = {}
        summary['investment'] = investment.toFixed(2)
        summary['holding'] = holding.toFixed(2)
        summary['value'] = value.toFixed(2)
        summary['turnover'] = turnover.toFixed(2)
        summary['profit'] = profit.toFixed(2)
        summary['delta'] = delta.toFixed(2)
        summary['margin'] = margin.toFixed(2)
        setSummary(summary)
    }, [accounts]);

    return (
        <>
        <Header/>
        <div className="falcon_table">
        <div className="falcon_table" style={{borderBottom: "1px solid #eee"}}>
        <h2 style={{marginTop:"1em"}}> Active Accounts </h2>
        <table>
        <AccHeader/>
        {accounts.map(acc => 
            (!acc.broker.includes('paper') && !(acc.broker == "simbroker") && acc.is_active == true) ?  <Acc acc={acc} /> : null
        )}
        </table>
        </div>
        
        <h2 style={{marginTop:"1em"}}> Simulation Accounts </h2>
        <table>
        <AccHeader/>
        {accounts.map(acc => 
            (acc.is_active == true && (acc.broker == 'simbroker' || acc.broker.includes('paper'))) ?  <Acc acc={acc} /> : null
        )}
        </table>

        </div>
        </>
    )
}

const AccountSummary = (props) => {
    return (
        <>
        <h2> Summary </h2>
        <table style={{width:"100%"}}>
        <tr>
        <th>Turnover</th>
        <th>Investment</th>
        <th>Holding</th>
        <th>Value</th>
        <th>Delta</th>
        <th>Delta Margin</th>
        <th>Profit</th>
        </tr>
        <tr>
        <td>$ {props.summary['turnover']}</td>
        <td>$ {props.summary['investment']}</td>
        <td>$ {props.summary['holding']}</td>
        <td>$ {props.summary['value']}</td>
        <td style={{color:props.summary['delta'] >=0 ? "green" : "red"}}>$ {props.summary['delta']}</td>
        <td style={{color:props.summary['margin'] >=0 ? "green" : "red"}}>{props.summary['margin']} %</td>
        <td>$ {props.summary['profit']}</td>
        </tr>
        </table>
        </>
    )
}

const AccHeader = () => {
    return(
        <tr>
        <th>Account<br/>[falcon]</th>
        <th>Broker<br/>[config]</th>
        <th>Turnover</th>
        <th>Capital</th>
        <th>Investment</th>
        <th>Holding <br/>[ Positions ]</th>
        <th>Market Value <br/>[ Orders ]</th>
        <th>P/L</th>
        <th>P/L %</th>
        <th>Profit</th>
        <th>Profit%</th>
        <th>Net P/L</th>
        </tr>
    )
}

const Acc = (props) => {

    const [delta, setDelta] = useState(0)
    const [margin, setMargin] = useState(0.0)
    const [profit_margin, setProfitMargin] = useState(0)
    const [net_delta, setNetDelta] = useState(0)

    useEffect(() => {
        let delta = props.acc.value - props.acc.holding
        let margin  = props.acc.holding > 0 ? (delta / props.acc.holding) * 100 : 0
        let profit = props.acc.profit
        let net_delta = profit + delta
        let profit_margin = (props.acc.holding> 0) ? (profit/props.acc.holding) * 100 : 0
        setDelta(delta)
        setMargin(margin)
        setProfitMargin(profit_margin)
        setNetDelta(net_delta)
    }, []);

    const convertTime = (timestamp) => {
      let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(timestamp * 1000)
      return date
    }

    let startDate = new Date()
    startDate.setHours(0, 0, 0)
    let endDate = new Date(new Date().getTime())
    
    return (
        <>
        <tr>
        <td className="account_name"> 
         <Link to="/prism/falcons" state={{account: props.acc}}>{props.acc.name}</Link>
        </td>
        <td> 
         <Link to="/prism/config" state={{account: props.acc}}>{props.acc.broker}</Link>
        </td>
        <td> {(props.acc.turnover != null) ? props.acc.turnover.toFixed(2) : 0} </td>
        <td>
         <Link to="/prism/capital" state={{account: props.acc}}>{props.acc.cash_allocated}</Link>
        </td>
        <td> {(props.acc.investment != null) ? props.acc.investment: 0} </td>
        <td className="account_name"> 
         <Link to="/prism/position" state={{account: props.acc}}>{props.acc.holding ? props.acc.holding.toFixed(2):0}</Link>
        </td>
        <td> 
            <Link to="/prism/orders" state={{account: props.acc, start_date: startDate, end_date:endDate}}> {(props.acc.value) ? props.acc.value.toFixed(2) : 0} </Link>
        </td>
        <td style={{color:delta >=0 ? "green" : "red"}}> {delta.toFixed(2)}</td>
        <td style={{color:margin >=0 ? "green" : "red"}}> {margin.toFixed(2)}% </td>
        <td className="account_name"> 
         <Link to="/prism/profit" state={{account: props.acc}}>{(props.acc) ? props.acc.profit.toFixed(2) : 0}</Link>
        </td>
        <td style={{color:"green"}} > {profit_margin.toFixed(2)}% </td>
        <td style={{color:(net_delta >= 0) ? "green" : "red"}}> {net_delta.toFixed(2)} </td>
        </tr>
        </>
    )
}


export default Account
