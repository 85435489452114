import React,{ useState, useEffect } from 'react';
import {Link, useLocation} from "react-router-dom"
import ReactDOM from 'react-dom';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import "./css/prism.css"
import Header from "../header"

const Buy = (props) => {
    const [disable_buy, setDisableBuy] = useState(false)
    const [buy_price, setBuyPrice] = useState(props.price)
    const [buy_quantity, setBuyQuantity] = useState(0)
    const [buy_cost, setBuyCost] = useState(0)
    const location = useLocation()
    const account = location.state['account']
    const falcon_name = location.state['falcon_name']
    const trading_ticker = location.state['ticker'].replace('-', '')

    const placeBuyOrder = () => {
        fetch("https://walmates.com/api/prism/userorder/add?user=" 
            + account.name + "&falcon=" + falcon_name + "&buy_price=" + buy_price
            + "&ticker=" + trading_ticker + "&quantity=" + buy_quantity)
        .then(result=>result.json())
        .then(data => {
            console.log("Received data:", data);
            resetBuy()
        });
    }

    const resetBuy = () => {
        setDisableBuy(false)
        setBuyPrice(props.price)
        setBuyQuantity(0)
        setBuyCost(0)
    }


    let idx = 0
    return (
        <>
        <div className="buy_table">
        <table>
        <tr>
        <th>Limit Price</th>
        <th> Quantity</th>
        <th> Cost </th>
        <th>Place Order</th>
        </tr>
        <tr>
            <td style={{background: disable_buy == false ? "white" : "#fafafa"}}>
                <input
                    type="text"
                    className="falcon_text"
                    disabled = {disable_buy}
                    value={buy_price}
                    onChange={(e) => {
                        setBuyPrice(e.target.value)
                        setBuyCost(e.target.value * buy_quantity)
                    }}
                />
            </td>
            <td style={{background: disable_buy == false ? "white" : "#fafafa"}}>
                <input
                    type="text"
                    className="falcon_text"
                    disabled = {disable_buy}
                    value={buy_quantity}
                    onChange={(e) => {
                        setBuyQuantity(e.target.value)
                        setBuyCost(buy_price * e.target.value)
                    }}
                />
            </td>
            <td style={{background: disable_buy == false ? "white" : "#fafafa"}}>{buy_cost}</td>
            <td>
              <button style={{ background: disable_buy ? "#ccc" : "#00e64d"}} className="buy_submit"
                onClick={() => {
                    if(disable_buy == false) {
                        setDisableBuy(true)
                        placeBuyOrder()
                    }
                }}> Place
              </button>
            </td>
          </tr>
        </table>
        </div>
        </>
    )
}

export default Buy
