import React,{ useState, useEffect } from 'react';
import {Link, useLocation} from "react-router-dom"
import ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios'

import "./css/prism.css"
import Header from "../header"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"

const Profit = () => {
    const [profit, setProfit] = useState([{profit:0, investment:0, breakdown:[], error:[]}]) 
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const location = useLocation()
    const account = location.state['account']
    const [sort_column, setSortColumn] = useState('turnover')
    const [sort_order, setSortOrder] = useState(true)
    const navigate = useNavigate();

    useEffect(() => {
        startDate.setHours(0, 0, 0)
        let start_time = parseInt(startDate.getTime()/1000)
        endDate.setHours(0, 0, 0)
        let end_time = parseInt(endDate.getTime()/1000) + 24 * 60 * 60
        let token = localStorage.getItem('walmates-token')
        axios.get("https://walmates.com/api/prism/profit/get?user=" + account.name + "&start_time=" + start_time + " &end_time=" + end_time, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            let data = result.data
            console.log("Received profit:", data)
            data?.breakdown?.map((p) => {p.margin = p.profit/p.turnover * 100})
            data?.breakdown?.sort((a, b) => a.turnover > b.turnover ? -1 : 1)
            setProfit(data)
        })
        .catch(err => {
          console.error(err)
          navigate("/login", {replace:true});
        });
    }, [startDate, endDate]);

    useEffect(()=> {
        let data = {...profit}
        if (data.breakdown != null) {
            data.breakdown = data.breakdown.sort((a, b) => { 
                if (sort_order == true) {
                    return a[sort_column] > b[sort_column] ? -1 : 1
                } else {
                    return a[sort_column] > b[sort_column] ? 1 : -1
                }
            });
            setProfit(data)
        }
    }, [sort_column, sort_order]);

    const onClickHandler = (col) => {
        setSortOrder(!sort_order)
        setSortColumn(col)
    }

    let idx = 0
    return (
        <>
        <Header/>
        <div className="search_bar">
        <div className="search_item">
        <DatePicker selected={startDate} onChange={(date:Date)=>setStartDate(date)}/>
        </div>
        <div className="search_item">
        <DatePicker selected={endDate} onChange={(date:Date)=>setEndDate(date)}/>
        </div>
        </div>
        <div className="spectrum_table">
        <table>
          <tr>
            <th>TurnOver</th>
            <th>Profit</th>
            <th>Margin</th>
          </tr>
          <tr>
            <td>$ {profit && profit.turnover ? profit.turnover.toFixed(2) : 0}</td>
            <td>$ {profit && profit.profit ? profit.profit.toFixed(2): 0}</td>
            <td>{profit && profit.margin ? profit.margin.toFixed(2): 0} %</td>
            </tr>
        </table>
        </div>
        { profit && profit.breakdown ? (
            <div className="spectrum_table">
            <table>
              <tr>
                <th>No</th>
                <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this, "falcon")}>Falcon</th>
                <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this, "ticker")}>Ticker</th>
                <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this, "turnover")}>TurnOver</th>
                <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this, "profit")}>Profit</th>
                <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this, "margin")}>Margin</th>
              </tr>

             {
                profit.breakdown.map(p => (
                <tr>
                  <td> {idx += 1} </td>
                  <td> 
                    <Link to="/prism/sales" state={{account: account, falcon_name: p.falcon, ticker: p.ticker, start_date: startDate, end_date: endDate}}>{p.falcon}</Link>
                  </td>
                  <td> {p.ticker}</td>
                  <td> {p.turnover.toFixed(2)}</td>
                  <td>$ {p.profit.toFixed(2)}</td>
                  <td> {p.margin.toFixed(2)} %</td>
                </tr>))
            }
            </table>
            </div> 
        ) : ""}

        </>
    )
}
export default Profit

