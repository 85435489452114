import React,{ useState, useEffect } from 'react';
import {Link, useLocation} from "react-router-dom"
import ReactDOM from 'react-dom';
import { FaTrash } from "react-icons/fa"
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import { SymbolInfo } from "react-ts-tradingview-widgets";
import { SymbolOverview } from "react-ts-tradingview-widgets";
import { TechnicalAnalysis } from "react-ts-tradingview-widgets";
import "./css/prism.css"
import Header from "../header"
import Buy from "./buy"

const Pending = (props) => {
    const [pending, setPending] = useState({orders:[]}) 
    const [orders, setOrders] = useState([])
    const location = useLocation()
    const account = location.state['account']
    const falcon_name = location.state['falcon_name']
    const trading_ticker = location.state['ticker'].replace('-', '')
    const navigate = useNavigate();

    const [show_buy, setShowBuy] = useState(false)

    useEffect(() => {
        if(account.mode == "man") {
            setShowBuy(true)
        }
        let token = localStorage.getItem('walmates-token')
        axios.get("https://walmates.com/api/prism/pending/get?user=" + account.name + "&falcon=" + falcon_name, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            let data = result.data
            console.log("Pending Orders Received: ", data)
            let p = {}
            p['investment'] = data['investment']
            p['value'] = data['value']
            p['delta'] = data['delta']
            p['margin'] = data['margin']
            p['price'] = data['price']
            setPending(p)
            setOrders(data['orders'])
        })
        .catch(err => {
          console.error(err)
          navigate("/login", {replace:true});
        });
    }, []);

    let idx = 0
    return (
        <>
        <Header/>
        <div className="falcon_table">
        <SymbolInfo symbol={trading_ticker} colorTheme="dark" autosize></SymbolInfo>
        {
            (show_buy) 
                ?  <div style={{marginLeft:"0.5em", color: "green"}}>: BUY </div>
                : null
        }
        </div>
        <div className="falcon_row">
        </div>
        <h2 className="falcon_row">
        {trading_ticker} : {pending['price']}
        </h2>
        {
            (show_buy) 
                ?  <UserOrder account={account} falcon_name={falcon_name} ticker={trading_ticker} price={pending['price']}/>
                : null
        }
        <div className="falcon_table">
        { (show_buy) ?  <h2>Positions</h2> : null }
        <table>
        <tr>
        <th>Falcon</th>
        <th>Total</th>
        <th>Investment</th>
        <th>Value</th>
        <th>P/L</th>
        <th>Margin</th>
        </tr>
        <tr>
        <td className="falcon_name"> 
         <Link to="/prism/spectrum" state={{account:account, falcon_name:falcon_name, ticker: trading_ticker}}>{falcon_name} </Link>
        </td>
        <td>{orders.length}</td>
        <td>$ {pending['investment']? pending['investment'].toFixed(2) : 0}</td>
        <td>$ {pending['value']? pending['value'].toFixed(2) : 0}</td>
        <td style={{color: pending['delta'] < 0 ? "red" : "green"}}>
            $ {pending['delta']? pending['delta'].toFixed(2) : 0}
        </td>
        <td style={{color: pending['margin'] < 0 ? "red" : "green"}}>
            {pending['margin']? pending['margin'].toFixed(2) : 0} %
        </td>
        </tr>
        </table>
        <table>
        <tr>
        <th>No</th>
        <th>Time</th>
        <th>Buy</th>
        <th>Invest</th>
        <th>Value</th>
        <th>P/L</th>
        <th>Margin</th>
        </tr>
        { orders.map(o => (
            <Cell account={account} price={pending['price']} index={idx +=1} cell={o} />
        ))}
        </table>
        </div>
        </>
    )
}

const Cell = (props) => {

    const [change, setChange] = useState(0)
    const [change_percentage, setChangePercentage] = useState(0)
    const [change_color, setChangeColor] = useState("red")
    const [sell_color, setSellColor] = useState("orangered")
    const [background_color, setBackgroundColor] = useState("#fff")

    useEffect(() => {
        let ch = props.cell.value - props.cell.investment
        let chp = ch/props.cell.investment * 100
        setChange(ch)
        setChangePercentage(chp)
        setChangeColor(ch >= 0 ? "green" : "red")
        setSellColor(ch > 0 ? "#00e64d" : "#ff5c33")
        setBackgroundColor(props.cell.sell_pending ? "#eeffe6" : "#fff")
    }, []);

    const convertTime = (timestamp) => {
      let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(timestamp * 1000)
      return date
    }

    return(
            <tr style={{background:background_color}}>
            <td> {props.index} </td>
            <td> {convertTime(props.cell.timestamp)} </td>
            <td style={{color:change_color}}>$ {props.cell.buy_price.toFixed(4)}</td>
            <td style={{color:change_color}}>$ {props.cell.investment.toFixed(4)}</td>
            <td style={{color:change_color}}>$ {props.cell.value.toFixed(4)}</td>
            <td style={{color:change_color}}>$ {change.toFixed(4)} </td>
            <td style={{color:change_color}}> {change_percentage.toFixed(4)} %</td>
            </tr>
    )
}


const UserOrder = (props) => {

    const [user_orders, setUserOrder] = useState([])
    useEffect(() => {
        fetch("https://walmates.com/api/prism/userorder/get?user=" + props.account.name + "&falcon=" + props.falcon_name)
        .then(result=>result.json())
        .then(data => {
            setUserOrder(data)
        });
    }, []);

    const cancelUserOrder = (uoid) => {
        fetch("https://walmates.com/api/prism/userorder/cancel?user=" + props.account.name + "&id=" + uoid)
        .then(result=>result.json())
        .then(data => {
            setUserOrder([
                ...user_orders.filter(o => {
                    return o.id != uoid;
                }),
            ])
        });
    }

    const convertTime = (timestamp) => {
      let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(timestamp * 1000)
      return date
    }

    return(
        <>
        <Buy account={props.account} falcon_name={props.falcon_name} ticker={props.ticker} price={props.price} /> 
        <div className="buy_table">
        <table>
        <tr>
        <th>Id</th>
        <th>Time</th>
        <th>Buy@</th>
        <th>Quantity</th>
        <th>Cost</th>
        <th>Action</th>
        </tr>
        { user_orders.map(o => (
            <tr> 
            <td>{o.id}</td>
            <td>{convertTime(o.timestamp)}</td>
            <td>$ {o.ask_price}</td>
            <td>{o.ask_quantity}</td>
            <td>$ {(o.ask_price * o.ask_quantity).toFixed(2)}</td>
            <td>
            <button style={{marginTop:"0.75em"}}
                onClick={() => {
                    if (window.confirm('Cancel your order: ' + o.id + ' ?')) {
                      cancelUserOrder(o.id);
                    }
                  }}>
                <FaTrash style={{color:"orangered", fontSize:"16px"}} />
            </button>
            </td>
            </tr>
        ))}
        </table>
        </div>
        </>
    )
}

export default Pending
