import React,{ useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom"
import './css/index.css';
import './css/login.css';
import Header from "./header"
import { useNavigate } from "react-router-dom";

async function loginUser(info) {
  const json = JSON.stringify(info)
  console.log("Submiting request", json)
  return fetch('https://walmates.com/api/login', {
      method: 'POST',
      body: json,
      headers: {
          'Content-Type': 'application/json'
      },
    })
    .then(result => result.json())
    .then(data => data)
}

const Login = () => {
  const [username, setUserName] = useState()
  const [password, setPassword] = useState()
  const navigate = useNavigate();
 
  const handleSubmit = async e => {
      e.preventDefault();
      loginUser({
          username, 
          password
      })
      .then(user_info => {
          console.log("RECEIVED INFO:", user_info)
          if (user_info.error) {
              throw user_info.error;
          }
          localStorage.setItem('walmates-token', user_info.token)
          localStorage.setItem('walmates-user_id', user_info.id)
          localStorage.setItem('walmates-login_id', user_info.login_id)
          navigate("/home", {replace:true})
      })
      .catch(err => {
        console.error(err)
        alert('Error in login: ' + String(err) + '.Please try again!');
       });
  }

  return(
    <>
    <Header/>
    <div className="login-wrapper">
        <form onSubmit={handleSubmit}> 
        <h1>Sign-In</h1>
        <label>
          <input type="text" placeholder="email-id" onChange={e => setUserName(e.target.value)}/>
        </label>
        <label>
          <input type="password" placeholder="password" onChange={e => setPassword(e.target.value)}/>
        </label>
        <div className="submit_button"> 
            <button type="submit">Submit</button>
        </div>
        </form>
    </div>
    </>
  )
}
export default Login

