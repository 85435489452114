import React, { useState, useEffect } from 'react';
import Header from '../header';
import { FaTrash } from "react-icons/fa"
import axios from 'axios'
import { useNavigate } from "react-router-dom";

import '../css/todo.css';
import '../css/todo_item.css';

const Todo = () => {
    const [todos, setTodos] = useState([])
    useEffect(() => {
        console.log("Loading Todos")
        let token = localStorage.getItem('walmates-token')
        let user_id = localStorage.getItem('walmates-user_id');
        axios.get(`https://walmates.com/api/todo/get?user_id=${user_id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(result => {
                let todos =[]
                result.data.map(todo => {
                    if(todo.parent_id == -1) {
                        todo.parent_key = "root";
                        todo.key = "root" + "-" + todo.id.toString().trim();
                        todos = [todo, ...todos]
                    }
                    else {
                        let p = todos.find(o => o.id === todo.parent_id);
                        if (p != null) {
                            todo.parent_key = p.key.trim()
                            todo.key = p.key + "-" + todo.id.toString().trim()
                            todos = [todo,...todos]
                        }
                        else {
                            console.log("title:", todo.title, " failed to find the key for parent:", todo.parent_id)
                        }
                    }
                })
                setTodos(todos)
            })
            .catch(err => {
                console.error("Failed to load todo:", err)
            });
    }, []);

    useEffect(() => {
    }, [todos]);

    const handleChange = (id) => {
        setTodos((prevState) =>
            prevState.map((todo) => {
                if (todo.id === id) {
                    return {
                        ...todo,
                        completed: !todo.completed,
                    };
                }
                return todo;
            })
        );
    };

    const addTodo = (todo) => {
        console.log("Adding Todo:", todo);
        if (todo.parent_id == null) {
            todo.parent_id = -1
            todo.parent_key = "root"
        }
        let token = localStorage.getItem('walmates-token')
        let user_id = localStorage.getItem('walmates-user_id');
        todo.user_id = user_id
        axios.post(`https://walmates.com/api/todo/add`, todo,  {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(result => {
                console.log("Todo", todo.title, " added successfully")
                // retrieve the todo
                axios.get(`https://walmates.com/api/todo/get?user_id=${user_id}&parent_id=${todo.parent_id}&title=${todo.title}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(result => {
                        console.log("Todo retrieved successfully", result.data[0])
                        let new_todo = result.data[0]
                        new_todo.parent_key = todo.parent_key
                        new_todo.key = todo.parent_key + "-" + new_todo.id
                        setTodos([new_todo, ...todos])
                    })
                    .catch(err => {
                        console.error("Error in retriving new todo", err)
                    })
            })
            .catch(err => {
                console.error(err)
                console.log("Failed to add todo:", todo.title)
            });
    };

    const updateTodo = (todo) => {
        console.log("Updating Task:", todo)
        if (todo == null || todo.id == null) {
            console.log("Invalid todo; ignoring update");
            return;
        }

        let token = localStorage.getItem('walmates-token')
        let user_id = localStorage.getItem('walmates-user_id');
        todo.user_id = user_id
        axios.post(`https://walmates.com/api/todo/update`, todo,  {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(result => {
                console.log("Todo", todo.title, " updated successfully")
            })
            .catch(err => {
                console.error(err)
                console.log("Failed to update todo:", todo.title)
                // TODO uncheck todo now
            });
    };

    const deleteTodo = (id) => {
        console.log("Deleting Todo:", id)
        if (id == null) {
            console.log("Invalid id, ignore delete")
            return
        }
        let token = localStorage.getItem('walmates-token')
        let user_id = localStorage.getItem('walmates-user_id');
        let params = {}
        params.id = id
        params.user_id = user_id
        axios.post(`https://walmates.com/api/todo/delete`, params,  {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(result => {
                console.log("Todo deleted successfully")
                setTodos((prevTodos) => prevTodos.filter((todo) => todo.id != id));
            })
            .catch(err => {
                console.log("Failed to update todo:", err)
                // TODO uncheck todo now
            });
    };

    const updateTitle = (key, updateTitle) => {
        setTodos((prevTodos) =>
            prevTodos.map((todo) => {
                if (todo.key === key) {
                    return {
                        ...todo,
                        title: updateTitle,
                    };
                }
                return todo;
            })
        );
    };

    const findTodos = (parent_key) => {
        console.log("Find todo for:", parent_key)
        const result = todos.filter((todo) => todo.parent_key === parent_key);
        return result || []
    };

    return (
        <div className="vault_table">
        <Header />
        <div style={{border:"1px solid #eee", padding:"20px"}}>
        <div className="input-container">
        <InputTodo addTask={addTodo}  placeHolder="Add Todo" />
        </div>
        {[
            ...findTodos("root").filter(task => !task.completed), // Uncompleted tasks go first
            ...findTodos("root").filter(task => task.completed)   // Completed tasks come after
        ].map(task => (
            <TaskList
            key={task.key}
            parent_id={-1}
            parent_key="root"
            all_tasks = {todos}
            addTask = {addTodo}
            deleteTask = {deleteTodo}
            updateTask = {updateTodo}
            findTasks = {findTodos}
            task={task}
            />
        ))}
        </div>
        </div>
    );
};

class TaskList extends React.Component {

    constructor(props) {
        super(props);
        //console.log("TaskList:", props)
        this.state = {
            task: props.task,
            show_tasks: false,
            tasks: props.findTasks(props.task.key),
        };
    }

    toggleShowTasks = () => {
        this.setState({
            show_tasks: !this.state.show_tasks,
        })
    };

    addTask = (child) => {
        child.parent_id = this.state.task.id
        child.parent_key = this.state.task.key
        if (child.parent_id == null) {
            console.log("Invalid Child Task:", child);
            return;
        }
        console.log("Adding Task to the childlist", child)
        this.setState([child, ...this.state.tasks])
        //this.props.addTask(child);
        let token = localStorage.getItem('walmates-token')
        let user_id = localStorage.getItem('walmates-user_id');
        child.user_id = user_id
        axios.post(`https://walmates.com/api/todo/add`, child,  {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(result => {
                console.log("Todo", child.title, " added successfully")
                this.setState(prevState => ({
                    tasks: [child, ...prevState.tasks]
                }));
            })
            .catch(err => {
                console.error(err)
                console.log("Failed to add todo:", child.title)
            });
    };


    deleteTask = (id) => {
        console.log("Deleting Task:", id);

        // Assuming deleteTask is a function passed as a prop from the parent component
        this.props.deleteTask(id); // Call the deleteTask function from props

        this.setState(prevState => ({
            tasks: prevState.tasks.filter(task => task.id !== id)
        }));
    };

    toggleTaskCompleted = () => {
        this.setState( prevState => ({
            task: {
                ...prevState.task, 
                completed: !prevState.task.completed
            }
        }), ()=> {
            this.props.updateTask(this.state.task)
        });
    }

    getColorFromKey = (key) => {
        let hyphensCount = 0;
        if (key != null) {
            hyphensCount = key.split('-').length - 1;
        }
        const grayscaleValue = 30 + (hyphensCount * 30); // Adjust 30 as per your preference
        // Create a CSS color value based on the grayscale
        const color = `rgb(${grayscaleValue}, ${grayscaleValue}, ${grayscaleValue})`;
        return color;
    };

    getTitleStyle = () => {
        let st = {}
        if (this.state.task.completed) {
            st.fontStyle = "italic"
            st.color = "#595959"
            st.opacity = 0.4
            st.textDecoration = "line-through"
        }
        if (this.state.tasks && this.state.tasks.length > 0) {
            st.fontWeight = "bold"
            st.fontSize = "18px"
            st.color = this.getColorFromKey(this.state.task.key)
            st.textDecoration = 'underline'
        }
        st.cursor = "pointer"
        return st;
    }

    render() {

        const completedStyle = {
            fontStyle: "italic",
            color: "#595959",
            opacity: 0.4,
            textDecoration: "line-through",
        }

        return (
            <li className="item"> 
            <div>
            <input 
            type="checkbox" 
            className="checkbox"
            checked={this.state.task.completed}
            onChange={this.toggleTaskCompleted}/> 
            <button 
            onClick={() => this.deleteTask(this.state.task.id)}>
            <FaTrash style={{ color: "grey", fontSize: "16px" }} />
            </button>
            <span style={this.getTitleStyle()} onClick={this.toggleShowTasks}>
            { this.state.task.title} 
            </span>

            {this.state.show_tasks && (
                <div className="subtask-outer-container">
                <div className="subtask-inner-container">
                <InputTodo addTask={this.addTask} placeHolder="Add Task" />
                {[
                    ...this.state.tasks.filter(task => !task.completed), // Uncompleted tasks go first
                    ...this.state.tasks.filter(task => task.completed),  // Completed tasks come after
                ].map(task => (
                    <TaskList
                    key={task.key}
                    parent_id={task.id}
                    parent_key={task.key}
                    all_tasks = {this.props.all_tasks}
                    addTask = {this.props.addTask}
                    updateTask = {this.props.updateTask}
                    deleteTask = {this.deleteTask} // parent delete task
                    findTasks = {this.props.findTasks}
                    task={task}
                    />
                ))}

                </div>
                </div>
            )}
            </div>
            </li>
        )
    }
}

const InputTodo = ({ addTask, placeHolder }) => {
    const [title, setTitle] = useState('');

    const handleInputChange = (event) => {
        setTitle(event.target.value);
    };

    const handleAddTask = () => {
        if (title.trim() !== '') {
            const task = {}
            task.title = title
            task.completed = false;
            addTask(task);
            setTitle('');
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleAddTask();
        }
    };

    return (
        <div className="input-container">
        <input
        type="text"
        value={title}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        placeholder={placeHolder}
        className="todo-input"
        />
        </div>
    );
};


export default Todo;
