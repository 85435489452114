import React,{ useState, useEffect } from 'react';
import {Link, useLocation} from "react-router-dom"
import ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios'

import "./css/prism.css"
import Header from "../header"
import { FaTrash } from "react-icons/fa"
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const LoanCapital = () => {
    const [capitals, setLoanCapitals] = useState([]) 
    const [loan_summary, setLoanSummary] = useState({})
    const [account_loan_allocated, setAccountLoanAllocated] = useState(0)
    const location = useLocation()
    const account = location.state['account']
    const prev_val = {}
    const [sort_column, setSortColumn] = useState('available')
    const [sort_order, setSortOrder] = useState(true)
    const [filter, setFilter] = useState("")
    const navigate = useNavigate();

    // for loan alllocation
    const [account_balance, setAccountBalance] = useState(0)
    const [depositable_loan, setDepositableLoan] = useState(0)


    useEffect(()=> {
        loan_summary['loan_allocated'] = 0
        loan_summary['loan_used'] = 0
        loan_summary['loan_available'] = 0
        capitals.map( c => loan_summary.loan_allocated += (c.loan_allocated) ? parseFloat(c.loan_allocated) : 0)
        capitals.map( c => loan_summary.loan_used += (c.loan_used) ? parseFloat(c.loan_used) : 0)
        capitals.map( c => loan_summary.loan_available += (c.loan_available) ? parseFloat(c.loan_available) : 0)
        loan_summary['loan_profit'] = loan_summary.loan_available + loan_summary.loan_used - loan_summary.loan_allocated
        setLoanSummary(loan_summary)
        setDepositableLoan(account_balance - loan_summary.loan_available - (account_loan_allocated - loan_summary.loan_allocated))
    }, [capitals, account_loan_allocated],
    );

    useEffect(() => {
        let token = localStorage.getItem('walmates-token')
        axios.get("https://walmates.com/api/prism/capital/get?user=" + account.name, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            let data = result.data
            console.log("Capitals Received", data)
            data.capitals.map((c) => {
                c.visible = true
                c.loan_qty = c.loan_available > 0 ? parseInt(c.loan_available / c.price) : 0;
                c.loan_profit = (c.loan_available + c.loan_used - c.loan_allocated)
                c.loan_margin = c.loan_allocated > 0 ? (c.loan_profit/c.loan_allocated) * 100 : 0
            })
            setAccountLoanAllocated(data.account_loan_allocated)
            setAccountBalance(data.account_balance)
            setLoanCapitals(data.capitals)
        })
        .catch(err => {
          console.error(err)
          navigate("/login", {replace:true});
        });
    }, []);

    useEffect(()=> {
        let data = [...capitals]
        data = data.sort((a, b) => { 
            if (sort_order == true) {
                return a[sort_column] > b[sort_column] ? -1 : 1
            } else {
                return a[sort_column] > b[sort_column] ? 1 : -1
            }
        })
        setLoanCapitals(data)

    }, [sort_column, sort_order]);

    useEffect(()=> {
        let data = [...capitals]
        data.map( c => 
            { c.visible = filter == "" || c.falcon.toUpperCase().includes(filter) }
        )
        setLoanCapitals(data)

    }, [filter]);

    const onClickHandler = (col) => {
        setSortOrder(!sort_order)
        setSortColumn(col)
    }

    const handleDepositLoanToAccount = (loan_amount) => {
        console.log("Add Loan:", loan_amount)
        loan_amount = parseFloat(loan_amount)
        if(loan_amount ==0) {
            console.log("ERROR invalid loan amount:", loan_amount)
            return
        }
        if (loan_amount > depositable_loan) {
            console.log("ERROR loan amount exceeds account balance allowed:", depositable_loan);
            return 
        }
        let token = localStorage.getItem('walmates-token')
        axios.get("https://walmates.com/api/prism/account/depositloan?user=" + account.name +
            "&amount=" + loan_amount, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function(result) {
            let data = result.data
            console.log("Deposit Loan Return Value:", data)
            if (data.loan_allocated != null) {
                setAccountLoanAllocated(data.loan_allocated)
            }
        });
    }

    const handleAllocateLoan = (capital, amount) => {
        if (amount == 0) {
            console.log("Invalid amount", amount);
            return
        }
        let total = parseInt(loan_summary.allocated) + parseInt(amount)
        if (account_loan_allocated < total) {
            console.log("ERROR: account deposit(" + account_loan_allocated + ") doesn't have enough to allocate: " + total)
            return
        }
        let token = localStorage.getItem('walmates-token')
        axios.get("https://walmates.com/api/prism/capital/allocate_loan?user=" + account.name +
            "&amount=" + amount + 
            "&falcon=" + capital.falcon, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function(result) {
            let data = result.data
            console.log("AllocateLoanReturn Value:", data)
            if (data.capital != null) {
                capital = data.capital
                const newCapitals = capitals.map(c => {
                    if (c.falcon == capital.falcon) {
                        c.loan_used = capital.loan_used
                        c.loan_available = capital.loan_available
                        c.loan_allocated = capital.loan_allocated
                        c.loan_qty = c.loan_available > 0 ? parseInt(c.loan_available / c.price) : 0;
                        c.loan_profit = c.loan_used + c.loan_available - c.loan_allocated
                        c.loan_margin = c.loan_profit/c.loan_allocated * 100
                    }
                    return c
                })
                setLoanCapitals(newCapitals)
            }
        });
    }

    let idx = 0
    return (
        <>
        <Header/>
        <div className="spectrum_table">
        <h2 style={{marginTop:"1em"}}> Loan Capital - {account.name} </h2>
        <table>
        <tr>
        <th>Loan Used</th>
        <th>Loan Available</th>
        <th>Loan Profit</th>
        <th>Loan Allocation <br/>Used</th>
        <th>Account <br/>Loan Allocated</th>
        <th>Account Balance</th>
        <th>Deposit Loan <br/> {depositable_loan} </th>
        </tr>
        <tr>
        <td>{loan_summary.loan_used? loan_summary.loan_used.toFixed(2): 0}</td>
        <td>{loan_summary.loan_available? loan_summary.loan_available.toFixed(2): 0}</td>
        <td style={{color:"green"}}>{loan_summary.loan_profit? loan_summary.loan_profit.toFixed(2): 0}</td>
        <td>{loan_summary.loan_allocated? loan_summary.loan_allocated.toFixed(2): 0}</td>
        <td>{account_loan_allocated}</td>
        <td>{account_balance}</td>
        <td style={{maxWidth:"5em", border: "1px solid #ccc"}}> 
            <input
            type="number" pattern="^-?[0-9]\d*\.?\d*$" 
            className="falcon_text"
            placeholder={0}
            style={{border:"1px solid #eee"}}
            onBlur={(e) => {
                handleDepositLoanToAccount(e.target.value)
            }}
        />
        </td>

        </tr>
        </table>
        </div>

        <div className="spectrum_table">
        <td style={{width:"30em", marginBottom: "2px"}}> 
            <input
            type="text"
            className="falcon_text"
            placeholder="Search:"
            onChange={(e) => {
                setFilter(e.target.value.trim().toUpperCase())
            }}
            />
        </td>
        <table>
        <tr>
        <th>No</th>
        <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"falcon")}>Falcon</th>
        <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"price")}>Price</th>
        <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"loan_used")}>Loan Used</th>
        <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"loan_available")}>Loan Available</th>
        <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"loan_qty")}>Loan Qty</th>
        <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"loan_allocated")}>Loan Allocated</th>
        <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"loan_profit")}>Loan Profit</th>
        <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"loan_margin")}>Loan Margin %</th>
        <th >Allocate Loan <br/>[{account_loan_allocated - loan_summary.loan_allocated}]</th>
        </tr>
        {
            capitals.map(c => (c.visible == true) ? 
                <tr>
                <td>{idx += 1}</td>
                <td> {c.falcon} </td>
                <td style={{background:"#eeffee"}}> {c.price.toFixed(4)} </td>
                <td>{c.loan_used ? c.loan_used.toFixed(2) : 0}</td>
                <td style={{color: (c.loan_allocated && (c.loan_available/c.loan_allocated * 100) <= 20) ? "red" : "green"}}>{c.loan_available.toFixed(2)}</td>
                <td>{c.loan_qty.toFixed(2)}</td>
                <td>{c.loan_allocated.toFixed(2)}</td>
                <td>{c.loan_profit.toFixed(2)}</td>
                <td>{c.loan_margin.toFixed(2)} %</td>
                <td style={{maxWidth:"4em", border:"1px solid #ccc"}}> 
                    <input
                    type="number" pattern="^-?[0-9]\d*\.?\d*$" 
                    className="falcon_text"
                    placeholder={0}
                    style={{border:"1px solid #eee"}}
                    onBlur={(e) => {
                        handleAllocateLoan(c, e.target.value)
                        e.target.value = 0
                    }}
                />
                </td>
                </tr>
            : null)
        }
        </table>
        </div>

        </>
    )
}
export default LoanCapital
