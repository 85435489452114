
const Header = () => {
    const headerStyle  = {
        padding: "20px 0",
        lineHeight: "1.5em",
    }
    return (
        <header style={headerStyle}>
        <div
          style={{
            fontSize: "4rem",
            fontWeight: "800",
            marginBottom: "1rem",
            lineHeight: "0.5em",
            color: "#ddd",
            textTransform: "lowercase",
            textAlign: "center",
          }}
        >
        </div>
        </header>
    )
}

export default Header
