import React,{ useState, useEffect } from 'react';
import {Link, useLocation} from "react-router-dom"
import ReactDOM from 'react-dom';
import Tooltip from "@material-ui/core/Tooltip";

import "./css/prism.css"
import Header from "../header"

const Heat = () => {
    const [heat, setHeat] = useState([]) 
    const location = useLocation()
    const account = location.state['account']
    const falconName = location.state['falcon_name']
    const ticker = location.state['ticker']
    const tradingTicker = ticker.replace('-', '')

    useEffect(() => {
        fetch("https://walmates.com/api/prism/heat/get?user=" + account.name + "&falcon=" + falconName)
        .then(result=>result.json())
        .then(data => {
            console.log("received response from heat ")
            setHeat([data])
        });
    }, []);


    let idx = 0
    return (
        <>
        <Header/>
        <div className="spectrum_table">
        {
            heat.map(s => (
                <HeatSummary 
                    account={account}
                    key={idx += 1} 
                    heat={s} 
                    falcon_name={falconName} 
                    ticker={tradingTicker}
            />))
        }
        </div>

        {
            heat.map(s => (
                <HeatView key={idx +=1 } heat={s}/>))
        }
        </>
    )
}
export default Heat

const HeatSummary = (props) => {

    const [total_sales, setTotalSales] = useState(0)

    useEffect(()=> {
        let sales = 0
        props.heat.layers.map( l => {
            l.cells.map(c => sales += c.num_sales)})
        setTotalSales(sales)
    }, []);
    return (
        <>
            <table>
                <tr>
                    <th>Falcon</th>
                    <th>Ticker</th>
                    <th>Price</th>
                    <th>Sales</th>
                </tr>

                <tr>
                    <td>{props.falcon_name}</td>
                    <td className="falcon_name"> {props.ticker} </td>
                    <td>{props.heat.price ? props.heat.price.toFixed(4): 0}</td>
                    <td>{total_sales}</td>
                </tr>
            </table>
        </>
    )
}

const HeatView = (props) => {
    let idx = 0
    return (
        <>
        {
            props.heat.layers.map(l => (
                    <LayerView layer_id={idx} key={idx+=1} price={props.heat.price} layer={l} heat={props.heat}/>
            ))
        }
        </>
    )
}

const LayerView = (props) => {

    const [layer_sales, setLayerSales] = useState(0)

    useEffect(()=> {
        let sales = 0
        props.layer.cells.map(c => sales += c.num_sales)
        setLayerSales(sales)
    }, []);

    let idx = 0
    return (
        <>
        <div className="spectrum_table">
        <table >
        <tr>
        <th>Level</th>
        <th>Cells</th>
        <th>High</th>
        <th>Low</th>
        <th>Gap</th>
        <th>Sales</th>
        </tr>
        <tr>
        <td>{props.layer_id + 1} </td>
        <td>{props.layer.cells.length} </td>
        <td>$ {props.layer.high_watermark.toFixed(2)} </td>
        <td>$ {props.layer.low_watermark.toFixed(2)} </td>
        <td>$ {props.layer.gap.toFixed(4)} </td>
        <td>{layer_sales} </td>
        </tr>
        </table>
        <div className="layer">
        {
            props.layer.cells.map(c => (
                <CellView cell_id={idx} key={idx+=1} price={props.price} cell={c} heat={props.heat}/>
            ))
        }
        </div>
        </div>
        </>
    )
}

const CellView = (props) => {

    const [cellStyle, setCellStyle] = useState("free_cell") 
    const [cellWrapperStyle, setCellWrapperStyle] = useState("cell_wrapper")
    const [count, setCount] = useState(0)
    useEffect(() => {
        let startDate = new Date()
        startDate.setHours(0, 0, 0)
        let start_time = parseInt(startDate.getTime()/1000)
        setCount(props.cell.num_sales)
        if (props.cell.end_price <= props.heat.high && props.cell.start_price >= props.heat.low) {
            setCellWrapperStyle("cell_wrapper_inrange")
        }
        if (props.cell.num_sales_today > 0) {
            setCellWrapperStyle("cell_wrapper_today")
        }
        if (!props.cell.is_free) {
            if (props.cell.buy_price <= props.price) {
                setCellStyle("profit_cell")
            } else {
                setCellStyle("loss_cell")
            }
            if(props.cell.timestamp > start_time) {
                setCellWrapperStyle("cell_wrapper_today");
            }
        } 
        if(props.price >= props.cell.start_price && props.price < props.cell.end_price) {
            setCellWrapperStyle("cell_wrapper_current");
        }
    }, []);

    return( 
        <>
        <Tooltip  title={
            "[" + props.cell.end_price.toFixed(4) + 
            ", " + 
            props.cell.start_price.toFixed(4) + "]" + 
            ((props.cell.buy_price != null) ? " Buy:" + props.cell.buy_price : "")
        }>
        <div className={cellWrapperStyle}>
        <div style={{borderRadius: "50%", fontSize:"10px"}} className={cellStyle} data-tip="hello"> {count} </div>
        </div>
        </Tooltip>
        </>
    )
}

