// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
    font-family: "Segoe UI", Arial, sans-serif;
    line-height: 1.4;
    color: \$444;
    background: #fff;
    height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
}

.inner {
  width: 100%;
  padding: 8rem 10px 4rem;
}

.form-container {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  border-radius: calc(0.5 * 100px);
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.38);
  justify-content: space-evenly;
}

.input-text {
  font-size: 1rem;
  font-weight: 400;
  width: 85%;
  padding-right: 5px;
  padding-left: 10px;
  border-radius: calc(0.5 * 100px);
}

.input-text::placeholder {
  color: #000;
}

.input-submit {
  background: transparent;
  color: #5b5b5b;
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 600;
  margin-right: 10px;
}

.input-text,
.input-submit {
  height: 45px;
  outline: none;
  border: none;
}

.input-submit svg {
    color: darkcyan;
    font-size: 20px;
    margin-top:2px;
}
`, "",{"version":3,"sources":["webpack://./src/css/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;AACZ;;AAEA;IACI,0CAA0C;IAC1C,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,aAAa;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,aAAa;EACb,gCAAgC;EAChC,2CAA2C;EAC3C,6BAA6B;AAC/B;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,UAAU;EACV,kBAAkB;EAClB,kBAAkB;EAClB,gCAAgC;AAClC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,cAAc;EACd,0BAA0B;EAC1B,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;;EAEE,YAAY;EACZ,aAAa;EACb,YAAY;AACd;;AAEA;IACI,eAAe;IACf,eAAe;IACf,cAAc;AAClB","sourcesContent":["* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\n\nbody {\n    font-family: \"Segoe UI\", Arial, sans-serif;\n    line-height: 1.4;\n    color: $444;\n    background: #fff;\n    height: 100vh;\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  max-width: 600px;\n  margin: 0 auto;\n}\n\n.inner {\n  width: 100%;\n  padding: 8rem 10px 4rem;\n}\n\n.form-container {\n  width: 100%;\n  margin-bottom: 20px;\n  display: flex;\n  border-radius: calc(0.5 * 100px);\n  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.38);\n  justify-content: space-evenly;\n}\n\n.input-text {\n  font-size: 1rem;\n  font-weight: 400;\n  width: 85%;\n  padding-right: 5px;\n  padding-left: 10px;\n  border-radius: calc(0.5 * 100px);\n}\n\n.input-text::placeholder {\n  color: #000;\n}\n\n.input-submit {\n  background: transparent;\n  color: #5b5b5b;\n  text-transform: capitalize;\n  cursor: pointer;\n  font-weight: 600;\n  margin-right: 10px;\n}\n\n.input-text,\n.input-submit {\n  height: 45px;\n  outline: none;\n  border: none;\n}\n\n.input-submit svg {\n    color: darkcyan;\n    font-size: 20px;\n    margin-top:2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
