import React,{ Component, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {Routes, Route, useParams } from "react-router-dom"
import { BrowserRouter as Router } from "react-router-dom"
import "./css/walmates.css"

import App from "./app"
import Walmates from "./walmates"
import Login from "./login"
import Titan from "./titan/titan"
import Account from "./prism/account"
import Falcons from "./prism/falcon"
import Configs from "./prism/config"
import Pending from "./prism/pending"
import Spectrum from "./prism/spectrum"
import Pyramid from "./prism/pyramid"
import Profit from "./prism/profit"
import Heat from "./prism/heat"
import Sales from "./prism/sales"
import Orders from "./prism/orders"
import FalconOrders from "./prism/falcon_orders"
import Position from "./prism/position"
import SellPending from "./prism/sell_pending"
import Capital from "./prism/capital"
import LoanCapital from "./prism/loan"
import MusicWidget from "./music/music"

import Vault from "./vault/vault"

import Todo from "./todo/todo"

ReactDOM.render(
    <Router>
        <Routes>
            <Route exact path="/" element={<App/>} />
            <Route exact path="/home" element={<Walmates/>} />
            <Route exact path="/login" element={<Login/>} />
            <Route exact path="/titan" element={<Titan/>} />
            <Route exact exact path="/prism/falcons" element={<Falcons/>} />
            <Route exact exact path="/prism/config" element={<Configs/>} />
            <Route exact exact path="/prism/pending" element={<Pending/>} />
            <Route exact exact path="/prism/sell_pending" element={<SellPending/>} />
            <Route exact exact path="/prism/spectrum" element={<Spectrum/>} />
            <Route exact exact path="/prism/profit" element={<Profit/>} />
            <Route exact exact path="/prism/position" element={<Position/>} />
            <Route exact exact path="/prism/heat" element={<Heat/>} />
            <Route exact exact path="/prism/sales" element={<Sales/>} />
            <Route exact exact path="/prism/orders" element={<Orders/>} />
            <Route exact exact path="/prism/falcon_orders" element={<FalconOrders/>} />
            <Route exact exact path="/prism/pyramid" element={<Pyramid/>} />
            <Route exact exact path="/prism/capital" element={<Capital/>} />
            <Route exact exact path="/prism/loan" element={<LoanCapital/>} />
            <Route exact path="/music" element={<MusicWidget/>} />
            <Route exact path="/todo" element={<Todo/>} />
            <Route exact path="/vault" element={<Vault/>} />
        </Routes>
    </Router>,
    document.getElementById("root")
)
