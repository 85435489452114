import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("walmates-token");
    if (token) {
      navigate("/home", {replace:true, hidePath:true});
    } else {
      navigate("/login", {replace:true, hidePath:true});
    }
    window.history.pushState(null, null, '/');
  }, [navigate]);

  return <div>Loading...</div>;
};

export default App;
