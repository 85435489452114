import React,{ useState, useEffect } from 'react';
import {Link, useLocation} from "react-router-dom"
import ReactDOM from 'react-dom';
import Tooltip from "@material-ui/core/Tooltip";
// import TradingViewWidget, { Themes, IntervalTypes, BarStyles } from 'react-tradingview-widget';
import { useNavigate } from "react-router-dom";
import axios from 'axios'

import "./css/prism.css"
import Header from "../header"

const Pyramid = () => {
    const [pyramid, setPyramid] = useState([]) 
    const location = useLocation()
    const account = location.state['account']
    const falcon_name = location.state['falcon_name']
    const trading_ticker = location.state['ticker'].replace('-', '')
    const navigate = useNavigate();

    useEffect(() => {
        console.log("get pyramid view")
        let token = localStorage.getItem('walmates-token')
        axios.get("https://walmates.com/api/prism/pyramid/get?user=" + account.name + "&falcon=" + falcon_name, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            let data = result.data
            console.log("received response from get_pyramid", data)
            setPyramid([data])
        })
        .catch(err => {
          console.error(err)
          navigate("/login", {replace:true});
        });
    }, []);

    let idx = 0
    return (
        <>
        <Header/>
        <div className="spectrum_table">
        {
            pyramid.map(s => (
                <PyramidSummary 
                    account={account}
                    key={idx += 1} 
                    pyramid={s} 
                    falcon_name={falcon_name}
                    ticker={trading_ticker}
            />))
        }
        </div>

        {
            pyramid.map(s => (
                <PyramidView key={idx +=1 } pyramid={s}/>))
        }
        </>
    )
}
export default Pyramid

const PyramidSummary = (props) => {

    return (
        <>
            <table>
                <tr>
                    <th>Falcon</th>
                    <th>Ticker</th>
                    <th>Num Layers</th>
                    <th>Seed</th>
                    <th>Unit</th>
                    <th>Count</th>
                    <th>Price</th>
                    <th>Level</th>
                    <th>Highest</th>
                    <th>Lowest</th>
                    <th>Holding</th>
                    <th>Available</th>
                    <th>Allocation</th>
                </tr>

                <tr>
                    <td className="falcon_name">
                        <Link to="/prism/pending" state={{account:props.account, falcon_name:props.falcon_name, ticker: props.ticker}}> {props.falcon_name} </Link>
                    </td>
                    <td>{props.ticker}</td>
                    <td>{props.pyramid.num_layers}</td>
                    <td>{props.pyramid.seed_capital}</td>
                    <td>{props.pyramid.allocation_unit}</td>
                    <td>{props.pyramid.total_orders}</td>
                    <td>{props.pyramid.price ? props.pyramid.price.toFixed(4): 0}</td>
                    <td>{props.pyramid.price ? ((props.pyramid.price - props.pyramid.lowest)/(props.pyramid.highest - props.pyramid.lowest) * 100).toFixed(2): 0}</td>
                    <td>{props.pyramid.highest ? props.pyramid.highest.toFixed(4): 0}</td>
                    <td>{props.pyramid.lowest ? props.pyramid.lowest.toFixed(4): 0}</td>
                    <td>{props.pyramid.used ? props.pyramid.used.toFixed(4) : 0}</td>
                    <td>{props.pyramid.available? props.pyramid.available.toFixed(4) : 0}</td>
                    <td>{props.pyramid.capital? props.pyramid.capital : 0}</td>
                </tr>
            </table>
        </>
    )
}

const PyramidView = (props) => {
    let idx = 0
    return (
        <>
        {
            props.pyramid.layers.map(l => (
                    <LayerView key={idx+=1} price={props.pyramid.price} layer={l} pyramid={props.pyramid}/>
            ))
        }
        </>
    )
}

const LayerView = (props) => {
    let idx = 0
    return (
        <>
        <div className="spectrum_table">
        <table >
        <tr>
        <th>Level</th>
        <th>High</th>
        <th>Low</th>
        <th>Gap</th>
        <th>Count</th>
        <th>Used</th>
        <th>Available</th>
        <th>Capital</th>
        </tr>
        <tr>
        <td>{props.layer.layer_id + 1} </td>
        <td>$ {props.layer.high_price.toFixed(6)} </td>
        <td>$ {props.layer.low_price.toFixed(6)} </td>
        <td>$ {props.layer.gap.toFixed(6)} </td>
        <td>{props.layer.order_count} </td>
        <td>$ {props.layer.used.toFixed(2)} </td>
        <td>$ {props.layer.available.toFixed(2)} </td>
        <td>$ {props.layer.capital.toFixed(2)} </td>
        </tr>
        </table>
        <div className="layer">
        {
            props.layer.cells.map(c => (
                <CellView cell_id={idx} key={idx+=1} price={props.price} cell={c} pyramid={props.pyramid}/>
            ))
        }
        </div>
        </div>
        </>
    )
}

const CellView = (props) => {

    const [cellStyle, setCellStyle] = useState("free_cell") 
    const [cellWrapperStyle, setCellWrapperStyle] = useState("cell_wrapper")

    useEffect(() => {
        let startDate = new Date()
        startDate.setHours(0, 0, 0)
        if (props.cell.high <= props.pyramid.today_high && props.cell.low >= props.pyramid.today_low) {
            setCellWrapperStyle("cell_wrapper_inrange")
        }
        if (!props.cell.is_free) {
            if (props.cell.low > props.price) {
                setCellStyle("profit_cell")
            } else {
                setCellStyle("loss_cell")
            }
            if (props.cell.buy_price > 0) {
                setCellStyle("buy_cell")
            }
        } 
        if(props.price >= props.cell.low && props.price < props.cell.high) {
            setCellWrapperStyle("cell_wrapper_current");
        }
    }, []);

    let price_str = (props.cell.buy_price) ? 'buy: ' + props.cell.buy_price : ""
    return( 
        <>
        <Tooltip  title={
            "[" + props.cell.high.toFixed(4) + 
            ", " + 
            props.cell.low.toFixed(4) + "] " + price_str
        }>
        <div className={cellWrapperStyle}>
        <div data-tip="hello" className={cellStyle}> { (props.cell.order_count > 1) ? props.cell.order_count : "." }</div>
        </div>
        </Tooltip>
        </>
    )
}

