import React,{ useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import EditIcon from '@mui/icons-material/Edit';
import {useLocation} from "react-router-dom"
import {Link} from "react-router-dom"

import "./css/prism.css"
import Header from "../header"
import EditableCell from "../common/editable_cell"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"

import { AdvancedRealTimeChart } from 'react-ts-tradingview-widgets';
import { PieChart, Pie, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, ResponsiveContainer, Cell, LabelList } from 'recharts';

const pie_colors = [
  "#FF6384", // Soft Red
  "#36A2EB", // Bright Blue
  "#FFCE56", // Vibrant Yellow
  "#4BC0C0", // Teal
  "#9966FF", // Purple
  "#FF9F40", // Orange
  "#E6E6FA", // Lavender
  "#8FBC8F", // Dark Sea Green
  "#FFD700", // Gold
  "#DC143C", // Crimson
  "#4682B4", // Steel Blue
  "#7CFC00", // Lawn Green
];

const ChartComponent = ({ ticker }) => {
  return (
    <AdvancedRealTimeChart
      key={ticker} // Forces re-render on ticker change
      symbol={ticker}
      width="100%"
      height="500"
      theme="light"
      interval="D"
      timezone="Etc/UTC"
      allow_symbol_change={true}
    />
  );
};

export const SectorBarChart = ({ chart_data, field, colors }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={chart_data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="sector" />
        <YAxis />
        <Tooltip formatter={(value) => value.toFixed(2)} />
        <Legend />
        <Bar dataKey={field}>
          {/* Add Cell to color the bars */}
          {chart_data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry[field] >= 0 ? colors[0] : colors[1]} />
          ))}
          {/* Add LabelList to display values */}
          <LabelList dataKey={field} position="top" formatter={(value) => value.toFixed(2)} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export const SectorPieChart = ({ chart_data, field, colors }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Tooltip formatter={(value) => value.toFixed(2)} />
        <Pie
          data={chart_data}
          dataKey={field}
          nameKey="sector"
          cx="50%"
          cy="50%"
          outerRadius={150}
          label={(entry) => `${entry.sector}: ${entry[field]}`}
        >
          {chart_data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

const Titan = () => {

    const [isEditMode, setIsEditMode] = useState(false)
    const [tab, setTab] = useState("stocks")

    const [stocks, setStocks] = useState([])
    const [summary, setSummary] = useState({})
    const [stock_filter, setStockFilter] = useState("")
    const [sort_column, setSortColumn] = useState('value')
    const [sort_order, setSortOrder] = useState(false)
    
    const [orders, setOrders] = useState([])
    const [order_summary, setOrderSummary] = useState({})
    const [order_filter, setOrderFilter] = useState("")
    const [order_start_date, setOrderStartDate] = useState(new Date())
    const [order_end_date, setOrderEndDate] = useState(new Date())

    const [stock_trades, setStockTrades] = useState([])
    const [trade_filter, setTradeFilter] = useState("")
    const [trade_summary, setTradeSummary] = useState({})
    const [trade_ticker, setTradeTicker] = useState(null)
    const [trade_start_date, setTradeStartDate] = useState(() => {
        const initialDate = new Date();
        initialDate.setMonth(initialDate.getMonth() - 3);
        return initialDate;
    });
    const [trade_end_date, setTradeEndDate] = useState(new Date())

    const [sector_data, setSectorChartData] = useState([])

    const navigate = useNavigate();

    const formatDate = (epoch) => {
        const date = new Date(epoch * 1000);  // Convert to milliseconds
        const day = String(date.getDate()).padStart(2, '0');      // Get day and pad with leading 0
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = String(date.getFullYear()).slice(2);         // Get last two digits of the year
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        
        return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
    };
    useEffect(() => {
        let token = localStorage.getItem('walmates-token')
        axios.get("https://walmates.com/api/titan/getStocks", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            let sd = []
            result.data.map((s) => 
            { 
                s.value = (s.price * s.quantity)
                s.delta = s.value - s.investment
                s.margin = s.investment > 0 ? (s.delta/s.investment * 100) : 0
                s.total_margin = s.max_investment > 0 ? (s.profit/s.max_investment * 100) : 0
                sd.push(s)
            })
            setStocks(sd)
        })
        .catch(err => {
          console.error(err)
          navigate("/login", {replace:true});
        });
    }, []);

    useEffect(()=> {
        let data = [...stocks]
        data = data.sort((a, b) => {
            if (sort_order == true) {
                return a[sort_column] > b[sort_column] ? -1 : 1
            } else {
                return a[sort_column] > b[sort_column] ? 1 : -1
            }
        })
        setStocks(data)

    }, [sort_column, sort_order]);

    useEffect(() => {
        order_start_date.setHours(0, 0, 0)
        let start_time = parseInt(order_start_date.getTime()/1000)
        order_end_date.setHours(0, 0, 0)
        let end_time = parseInt(order_end_date.getTime()/1000) + 24 * 60 * 60

        let token = localStorage.getItem('walmates-token')
        axios.get(`https://walmates.com/api/titan/getOrders?start_time=${start_time}&end_time=${end_time}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            setOrders(result.data.data)
        })
        .catch(err => {
          console.error(err)
        });
    }, [order_start_date, order_end_date]);


    useEffect(() => {
        if (trade_ticker != null) {
            let start_time = parseInt(trade_start_date.getTime()/1000)
            trade_end_date.setHours(0, 0, 0)
            let end_time = parseInt(trade_end_date.getTime()/1000) + 24 * 60 * 60

            let token = localStorage.getItem('walmates-token')
            axios.get(`https://walmates.com/api/titan/getOrders?start_time=${start_time}&end_time=${end_time}&ticker=${trade_ticker}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(result => {
                setStockTrades(result.data.data)
            })
            .catch(err => {
              console.error(err)
            });
        }
    }, [trade_ticker, trade_start_date, trade_end_date]);

    useEffect(()=> {
        const updatedOrders = orders.map(o => ({
            ...o,
            visible: order_filter == "" || o.side.toUpperCase().includes(order_filter) || o.ticker.toUpperCase().includes(order_filter) || o.state.toUpperCase().includes(order_filter)
        }));
        setOrders(updatedOrders)
    }, [order_filter])

    useEffect(()=> {
        const filter = stock_filter.toUpperCase()
        const updatedStocks= stocks.map(s => ({
            ...s,
            visible: filter == "" || s.name.toUpperCase().includes(filter) || s.ticker.toUpperCase().includes(filter) || s?.category.toUpperCase().includes(filter)
        }));
        setStocks(updatedStocks)
    }, [stock_filter])

    useEffect(()=> {
        const stockExists = stocks.some(stock => stock.ticker.toLowerCase() === trade_filter.toLowerCase());
        if (stockExists) {
            setTradeTicker(trade_filter)
        }
    }, [trade_filter])

    useEffect(()=> {
        let sum = {}
        let scdata = {}

        sum.investment = 0
        sum.value = 0
        sum.delta = 0
        sum.profit = 0
        sum.net = 0
        sum.margin = 0
        stocks.map((s) => 
        { 
            sum.investment += s.investment
            sum.value += s.value
            sum.profit += s.profit

            if (!scdata[s.category]) {
                scdata[s.category] = { delta: 0, investment: 0, profit: 0, holdings: 1};
            }
            else {
                scdata[s.category].holdings += 1
            }
            scdata[s.category].delta += s.value - s.investment;
            scdata[s.category].investment += s.investment;
            scdata[s.category].profit += s.profit;
        })
        sum.delta = sum.value - sum.investment
        sum.net = sum.profit + sum.delta
        sum.margin = sum.net/sum.investment*100
        setSummary(sum)

        // create the sector summary
        const sc_data = Object.entries(scdata).map(([sector, { delta, investment, profit, holdings }]) => ({
            sector,
            delta,
            investment,
            profit,
            holdings
        }));
        setSectorChartData(sc_data)

    }, [stocks]);

    useEffect(()=> {
        const sum = {}
        sum.buy = 0
        sum.sell = 0
        sum.net = 0
        sum.profit = 0
        orders.map((o) => 
        { 
            if (o.visible == null || o.visible == true) {
                if (o.side == "buy") {
                    sum.buy += o.value
                } else {
                    sum.sell += o.value
                    sum.profit += o.profit
                }
            }
        })
        sum.net = sum.buy - sum.sell
        setOrderSummary(sum)
    }, [orders]);

    useEffect(()=> {
        const sum = {}
        sum.buy_quantity = 0
        sum.buy_value = 0
        sum.sell_quantity = 0
        sum.sell_value = 0
        sum.profit = 0
        stock_trades.map((o) => 
        { 
            if (o.side == "buy") {
                sum.buy_quantity += o.quantity
                sum.buy_value += o.value
            } else {
                sum.sell_quantity += o.quantity
                sum.sell_value += o.value
                sum.profit += o.profit
            }
        })
        setTradeSummary(sum)
    }, [stock_trades]);

    const switchToTradeView = (ticker) => {
        setTradeFilter(ticker)
        handleTabSelect("trades")
    }
    const filterStocks= (category) => {
        setStockFilter(category.toUpperCase())
    }

    const onHeaderClickHandler = (col) => {
        setSortOrder(!sort_order)
        setSortColumn(col)
    }

    const restorePrevious = (s) => {
        console.log("Restoring Previous value for falcon", s.name)
        setStocks(prevState =>
            prevState.map(stock => {
                if (stock.name == s.name) {
                    return {
                        ...stock,
                        name: s.name,
                        allow_sell: s.allow_sell,
                        allow_buy: s.allow_buy,
                        min_gain: s.min_gain,
                        buy_limit: s.buy_limit,
                        sell_limit: s.sell_limit,
                        max_capital: s.max_capital
                    }
                }
                return stock;
            })
        )
    }


    const updateStockField = (ticker, field, value) => {
        let token = localStorage.getItem('walmates-token')
        console.log("Token:", token);
        let payload = {
            ticker:ticker, 
            field: field, 
            value: value
        };

        axios.post(`https://walmates.com/api/titan/updateStock`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            console.log("Data:", result.data)
            console.log(ticker + " field:" + field + " value:" + value + " updated");
        })
        .catch(err => {
          console.error(err)
        });
    }
    const handleEditClick = () => {
        setIsEditMode(!isEditMode)
    }
    const handleTabSelect = (mode) => {
        setTab(mode)
    }
    const getRowBackgroundColor = (o) => {
        if (o.side == "sell") {
            return "#f3fff3"
        }
        if (o.state == "completed") {
            return "#f3f3ff"
        }
        return "#fff"
    }
    let count = 0
    let orderCount = 0
    return (
        <>
        <Header/>
        <Summary summary={summary}/>
        
        <div className="falcon_table" style={{border: "1px solid #eee"}}>
        <div style={{ display: 'flex', borderBottom:"1px solid #eee"}}>
        <h2 style={{ 
            paddingLeft: "1em", 
            paddingRight:"1em",  
            cursor:"pointer",
            background: tab === "stocks" ? "#f3f3f3" : "#fff",
            color: tab === "stocks" ? "black" : "grey", 
            textDecoration: tab === "stocks" ? 'underline' : null  }}
        onClick={() => handleTabSelect("stocks")}
        >Stocks</h2>
        <h2 style={{ 
            paddingLeft: "1em", 
            paddingRight:"1em",  
            cursor:"pointer",
            background: tab === "orders" ? "#f3f3f3" : "#fff",
            color: tab === "orders" ? "black" : "grey", 
            textDecoration: tab === "orders" ? 'underline' : null  }}
        onClick={() => handleTabSelect("orders")}
        >Orders</h2>
        <h2 style={{ 
            paddingLeft: "1em", 
            paddingRight:"1em",  
            cursor:"pointer",
            background: tab === "trades" ? "#f3f3f3" : "#fff",
            color: tab === "trades" ? "black" : "grey", 
            textDecoration: tab === "trades" ? 'underline' : null  }}
        onClick={() => handleTabSelect("trades")}
        >Trades</h2>
        <h2 style={{ 
            paddingLeft: "1em", 
            paddingRight:"1em",  
            cursor:"pointer",
            background: tab === "sector" ? "#f3f3f3" : "#fff",
            color: tab === "sector" ? "black" : "grey", 
            textDecoration: tab === "sector" ? 'underline' : null  }}
        onClick={() => handleTabSelect("sector")}
        >Charts</h2>
        </div>
        
        { tab === "stocks" ? (
            <>
            <div style={{display: 'flex',borderBottom:"1px solid #eee"}}>
            <div style={{margin:"5px", width:"8em", border:"1px solid #eee"}}> 
                <input
                type="text"
                className="falcon_text"
                placeholder="Search:"
                value = {stock_filter || undefined}
                onChange={(e) => {
                    console.log("Set filter:", e.target.value.trim().toUpperCase())
                    setStockFilter(e.target.value.trim().toUpperCase())
                }}
                />
            </div>
            <EditIcon style={{ cursor: 'pointer', marginTop:"10px", border:"1px solid #eee"}} onClick={handleEditClick} />
            </div>
            <table>
            <StockHeader isEditMode={isEditMode} onClickHandler={onHeaderClickHandler}/>
            {stocks.map((s, index) => (s.visible == null || s.visible == true) ? (
                    <>
                    { (isEditMode || s.allow_buy) ? (
                    <tr style={{borderTop:"1px solid #eee", background:s.allow_buy === 0 ? "#f3f3f3" : "#fff"}}>
                    <td>{++count}</td>
                    <td>
                      {!isEditMode ? ( s.name) : (
                        <EditableCell cellkey={s.ticker}  field="name" value={s.name} onSave={updateStockField} />
                      )}
                    </td>
                    <td onClick={() => switchToTradeView(s.ticker)} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
                        {s.ticker}
                    </td>
                    {
                        !isEditMode ?
                        ( <td onClick={() => setStockFilter(s.category)} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
                                {s.category}
                          </td>
                        ) : ( <td> <EditableCell cellkey={s.ticker} field="category" value={s.category} onSave={updateStockField}/> </td>)
                    }
                    {isEditMode? (
                        <>
                        <td> <EditableCell cellkey={s.ticker} field="allow_buy" value={s.allow_buy} onSave={updateStockField}/> </td>
                        <td> <EditableCell cellkey={s.ticker} field="allow_sell" value={s.allow_sell} onSave={updateStockField}/> </td>
                        <td> <EditableCell cellkey={s.ticker} field="buy_limit" value={s.buy_limit} onSave={updateStockField}/> </td>
                        <td> <EditableCell cellkey={s.ticker} field="sell_limit" value={s.sell_limit} onSave={updateStockField}/> </td>
                        <td> <EditableCell cellkey={s.ticker} field="max_capital" value={s.max_capital} onSave={updateStockField}/> </td>
                        <td> <EditableCell cellkey={s.ticker} field="min_gain" value={s.min_gain} onSave={updateStockField}/> </td>
                        </>
                    ) :null
                    }
                    <td>{s.price? s.price.toFixed(2): 0}</td>
                    <td>{s.average_price ? s.average_price.toFixed(2): 0}</td>
                    <td>{s.quantity ? s.quantity.toFixed(3): 0}</td>
                    <td>{s.investment? s.investment.toFixed(2): 0}</td>
                    <td>{s.value.toFixed(2)}</td>
                    <td style={{color: s.delta >=0 ? "green" : "red"}} >${s.delta.toFixed(2)}</td>
                    <td>{s.margin.toFixed(2)}%</td>
                    <td>{s.max_investment? s.max_investment.toFixed(2): 0}</td>
                    <td>{s.profit? s.profit.toFixed(2): 0}</td>
                    <td>{s.total_margin.toFixed(2)}%</td>
                    </tr>
                    ) : null
                    }
                    </>
                ) : null
            )}
            </table>
            </>
        ) : tab === "orders" ? (
            <>
            <div style={{display:"flex", padding:"2px", marginTop:"5px"}}>
            <div style={{marginLeft:"5px", marginRight:"10px", marginBottom:"8px", width:"8em", border:"1px solid #eee"}}> 
                <input
                type="text"
                className="falcon_text"
                placeholder="Search:"
                onChange={(e) => {
                    console.log("Set filter:", e.target.value.trim().toUpperCase())
                    setOrderFilter(e.target.value.trim().toUpperCase())
                }}
                />
            </div>
            <div style={{marginRight:"5px"}}>
            <DatePicker selected={order_start_date} onChange={(date:Date)=>setOrderStartDate(date)}/>
            </div>
            <div style={{marginLeft:"5px"}}>
            <DatePicker selected={order_end_date} onChange={(date:Date)=>setOrderEndDate(date)}/>
            </div>
            </div>
            <OrderSummary summary={order_summary}/>
            <table>
                <thead>
                    <tr style={{ textAlign: 'left' }}>
                        <th>No</th>
                        <th>Time</th>
                        <th>Ticker</th>
                        <th>Side</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Value</th>
                        <th>Profit</th>
                        <th>Margin</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((o, index) => (o.visible == null || o.visible == true) ? (
                        <tr key={index} style={{background:getRowBackgroundColor(o)}}>
                            <td>{++orderCount}</td>
                            <td>{o.timestamp ? formatDate(o.timestamp) : 0}</td>
                            <td onClick={() => switchToTradeView(o.ticker)} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
                                {o.ticker}
                            </td>
                            <td>{o.side ? o.side : "unknown"}</td>
                            <td>{o.price ? o.price.toFixed(2) : 0}</td>
                            <td>{o.quantity ? o.quantity.toFixed(3) : 0}</td>
                            <td>$ {(o.value).toFixed(2)}</td>
                            <td>$ {o.profit? o.profit.toFixed(2) : 0}</td>
                            <td>{(o.profit/o.value * 100).toFixed(2)}%</td>
                        </tr>
                    ) : null)}
                </tbody>
            </table>
            </>
        ) : tab === "trades" ? (
            <div className="falcon_table">
            <ChartComponent ticker={trade_ticker}/>
            <div style={{display:"flex", padding:"2px", marginTop:"5px"}}>
            <div style={{marginLeft:"5px", marginRight:"10px", marginBottom:"8px", width:"8em", border:"1px solid #eee"}}> 
                <input
                type="text"
                className="falcon_text"
                placeholder="Search:"
                onChange={(e) => {
                    console.log("Set filter:", e.target.value.trim().toUpperCase())
                    setTradeFilter(e.target.value.trim().toUpperCase())
                }}
                />
            </div>
            <div style={{marginRight:"5px"}}>
            <DatePicker selected={trade_start_date} onChange={(date:Date)=>setTradeStartDate(date)}/>
            </div>
            <div style={{marginLeft:"5px"}}>
            <DatePicker selected={trade_end_date} onChange={(date:Date)=>setTradeEndDate(date)}/>
            </div>
            </div>
            <TradeSummary summary={trade_summary}/>
            <table>
                <thead>
                    <tr style={{ textAlign: 'left' }}>
                        <th>No</th>
                        <th>Time</th>
                        <th>Ticker</th>
                        <th>Side</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Value</th>
                        <th>Profit</th>
                        <th>Margin</th>
                    </tr>
                </thead>
                <tbody>
                    {stock_trades.map((o, index) => (
                        <tr key={index} style={{background:getRowBackgroundColor(o)}}>
                            <td>{++orderCount}</td>
                            <td>{o.timestamp ? formatDate(o.timestamp) : 0}</td>
                            <td>{o.ticker ? o.ticker : "unknown"}</td>
                            <td>{o.side ? o.side : "unknown"}</td>
                            <td>{o.price ? o.price.toFixed(2) : 0}</td>
                            <td>{o.quantity ? o.quantity.toFixed(3) : 0}</td>
                            <td>$ {(o.value).toFixed(2)}</td>
                            <td>$ {o.profit? o.profit.toFixed(2) : 0}</td>
                            <td>{(o.profit/o.value * 100).toFixed(2)}%</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
        ) : (
            <div className="falcon_table">
              <h2 style={{color:pie_colors[0], marginLeft:"2em"}}>Sector Holdings</h2>
              <SectorPieChart chart_data={sector_data} field="holdings" colors={pie_colors}/>
              <h2 style={{color:"#3498db", marginLeft:"2em"}}>Sector Investment</h2>
              <SectorBarChart chart_data={sector_data} field="investment" colors={["#3498db", "#2ecc71"]}/>
              <h2 style={{color:"#ff6b6b", marginLeft:"2em"}}>Sector Position </h2>
              <SectorBarChart chart_data={sector_data} field="delta" colors={["#82ca9d", "#ff6b6b"]}/>
              <h2 style={{color:"#2ecc71", marginLeft:"2em"}}>Sector Profit</h2>
              <SectorBarChart chart_data={sector_data} field="profit" colors={["#2ecc71", "#3498db"]}/>
            </div>
        )}
        </div>
        </>)
}

const StockHeader = (props) => {
    return(
        <tr style={{ textAlign: 'left' }}>
        <th>No</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "name")}>Stock</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "ticker")}>Ticker</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "category")}>Category</th>
        {
            props.isEditMode? (
            <>
            <th>Allow Buy</th>
            <th>Allow Sell</th>
            <th>Buy Limit</th>
            <th>Sell Limit</th>
            <th>Max Capital</th>
            <th>Min Gain</th>
            </>
            ) : null
        }
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "price")}>Price</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "average_price")}>Avg Price</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "quantity")}>Quantity</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "investment")}>Investment</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "value")}>Value</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "delta")}>Delta</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "margin")}>Margin</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "max_investment")}>Max Investment</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "profit")}>Profit</th>
        <th style={{cursor:"pointer"}} onClick={props.onClickHandler.bind(this, "total_margin")}>Net %</th>
        </tr>
    )
}

const Summary  = (props) => {
    return(
        <div className="falcon_table" style={{border: "1px solid #eee"}}>
        <h2 style={{ paddingLeft: "5px", paddingRight:"1em", borderRight:"1px solid #eee" }}>Summary</h2>
        <table>
        <tr style={{ textAlign: 'left' }}>
        <th>Investment</th>
        <th>Value</th>
        <th>Delta</th>
        <th>Profit</th>
        <th>Net</th>
        <th>Margin</th>
        </tr>
        <td>{props.summary.investment ? props.summary.investment.toFixed(2) : 0}</td>
        <td>{props.summary.value ? props.summary.value.toFixed() : 0}</td>
        <td style={{color:props.summary.delta < 0 ? "red" : "green"}} >{props.summary.delta ? props.summary.delta.toFixed(2) : 0}</td>
        <td style={{color:"green"}}>{props.summary.profit ? props.summary.profit.toFixed(2) : 0}</td>
        <td style={{color:props.summary.net < 0 ? "red" : "green"}} >{props.summary.net ? props.summary.net.toFixed(2) : 0}</td>
        <td style={{color:props.summary.margin < 0 ? "red" : "green"}} >{props.summary.margin ? props.summary.margin.toFixed(2) : 0}%</td>
        </table>
        </div>
    )
}

const OrderSummary  = (props) => {
    return(
        <table>
        <tr style={{ textAlign: 'left' }}>
        <th>Buy</th>
        <th>Sell</th>
        <th>Net</th>
        <th>Profit</th>
        </tr>
        <td>{props.summary.buy? props.summary.buy.toFixed(2) : 0}</td>
        <td>{props.summary.sell ? props.summary.sell.toFixed(2) : 0}</td>
        <td>{props.summary.net ? props.summary.net.toFixed(2) : 0}</td>
        <td>{props.summary.profit ? props.summary.profit.toFixed(2) : 0}</td>
        </table>
    )
}
const TradeSummary  = (props) => {
    return(
        <table>
        <tr style={{ textAlign: 'left' }}>
        <th>Buy Quantity</th>
        <th>Sell Quantity</th>
        <th>Buy Value</th>
        <th>Sell Value</th>
        <th>Profit</th>
        </tr>
        <td>{props.summary.buy_quantity? props.summary.buy_quantity.toFixed(2) : 0}</td>
        <td>{props.summary.sell_quantity ? props.summary.sell_quantity.toFixed(2) : 0}</td>
        <td>{props.summary.buy_value? props.summary.buy_value.toFixed(2) : 0}</td>
        <td>{props.summary.sell_value? props.summary.sell_value.toFixed(2) : 0}</td>
        <td>{props.summary.profit? props.summary.profit.toFixed(2) : 0}</td>
        </table>
    )
}
export default Titan
