// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width:500px;
    font-family: Tahoma, Geneva, sans-serif;
    border: 1px solid #eee;
    background:#f6f6f6;
    box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
}

.login-wrapper h1 {
    text-align: center;
    color:blue;
    font-size: 24px;
    padding: 10px 0 10px 0;
}

.login-wrapper form {
    display: flex;
    flex-direction: column;
}

.login-wrapper input[type=text],
.login-wrapper input[type=password],
.submit_button {
    width:330px;
    padding:15px;
    border: 1px solid #ccc;
    margin:10px;
    box-sizing:border-box;
    border-radius: 7px;
    font-size:20px;
    background-color :#fff;
}
.submit_button {
    text-align:center;
    background:#1877f2;
    margin-bottom:50px;
}
.submit_button button {
    color:#fff;
    font-weight:600;
    font-size:24px;
}
`, "",{"version":3,"sources":["webpack://./src/css/login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;IACd,WAAW;IACX,uCAAuC;IACvC,sBAAsB;IACtB,kBAAkB;IAClB,oFAAoF;AACxF;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;;;IAGI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,WAAW;IACX,qBAAqB;IACrB,kBAAkB;IAClB,cAAc;IACd,sBAAsB;AAC1B;AACA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,UAAU;IACV,eAAe;IACf,cAAc;AAClB","sourcesContent":[".login-wrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin: 0 auto;\n    width:500px;\n    font-family: Tahoma, Geneva, sans-serif;\n    border: 1px solid #eee;\n    background:#f6f6f6;\n    box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;\n}\n\n.login-wrapper h1 {\n    text-align: center;\n    color:blue;\n    font-size: 24px;\n    padding: 10px 0 10px 0;\n}\n\n.login-wrapper form {\n    display: flex;\n    flex-direction: column;\n}\n\n.login-wrapper input[type=text],\n.login-wrapper input[type=password],\n.submit_button {\n    width:330px;\n    padding:15px;\n    border: 1px solid #ccc;\n    margin:10px;\n    box-sizing:border-box;\n    border-radius: 7px;\n    font-size:20px;\n    background-color :#fff;\n}\n.submit_button {\n    text-align:center;\n    background:#1877f2;\n    margin-bottom:50px;\n}\n.submit_button button {\n    color:#fff;\n    font-weight:600;\n    font-size:24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
