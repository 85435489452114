import React,{ useState, useEffect } from 'react';
import {Link, useLocation} from "react-router-dom"
import ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios'

import "./css/prism.css"
import Header from "../header"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"

const Position = () => {
    const [positions, setPositions] = useState([{positions:[], margin:0, breakdown:[], error:[]}]) 
    const [summary, setSummary] = useState({})
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const location = useLocation()
    const account = location.state['account']
    const [sort_column, setSortColumn] = useState('investment')
    const [sort_order, setSortOrder] = useState(true)
    const navigate = useNavigate();

    useEffect(() => {
        startDate.setHours(0, 0, 0)
        let start_time = parseInt(startDate.getTime()/1000)
        endDate.setHours(0, 0, 0)
        let end_time = parseInt(endDate.getTime()/1000) + 24 * 60 * 60

        let token = localStorage.getItem('walmates-token')
        axios.get("https://walmates.com/api/prism/position/get?user=" + account.name + "&start_time=" + start_time + " &end_time=" + end_time, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            let data = result.data
            console.log("start:" + start_time + " end:" + end_time)
            console.log(data)
            data.positions.map((p) => {p.value = (p.quantity > 0 && p.price > 0) ? (p.quantity * p.price) : 0})
            data.positions.map((p) => {p.delta = p.value - p.holding})
            data.positions.map((p) => {p.margin = (p.value > 0) ? ((p.value - p.holding)/p.holding) * 100 : 0})

            let summary = {}
            summary.investment = 0
            summary.max_holding = 0
            summary.holding = 0
            summary.value = 0
            data.positions.map((p) => {
                summary.investment += p.investment
                summary.max_holding += p.max_holding
                summary.holding += p.holding
                summary.value += p.value
            })
            summary.delta = summary.value - summary.holding
            summary.margin = (summary.holding > 0) ? summary.delta/summary.holding * 100 : 0
            setPositions(data.positions)
            setSummary(summary)
        })
        .catch(err => {
          console.error(err)
          navigate("/login", {replace:true});
        });
    }, [startDate, endDate]);

    useEffect(()=> {
        let data = [...positions]
        data = data.sort((a, b) => { 
            if (sort_order == true) {
                return a[sort_column] > b[sort_column] ? -1 : 1
            } else {
                return a[sort_column] > b[sort_column] ? 1 : -1
            }
        })
        setPositions(data)
    }, [sort_column, sort_order]);

    const onClickHandler = (col) => {
        setSortOrder(!sort_order)
        setSortColumn(col)
    }

    let idx = 0
    return (
        <>
        <Header/>
        <div className="search_bar">
        <div className="search_item">
        <DatePicker selected={startDate} onChange={(date:Date)=>setStartDate(date)}/>
        </div>
        <div className="search_item">
        <DatePicker selected={endDate} onChange={(date:Date)=>setEndDate(date)}/>
        </div>
        </div>
        <div className="spectrum_table">
        <h2> Positions: { account.name }</h2>
        <table>
        <PositionSummary summary={summary} />
        </table>
        </div>

        { positions ? (
            <div className="spectrum_table">
            <table>
              <tr>
                <th>No</th>
                <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"falcon")}>Falcon</th>
                <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"ticker")}>Ticker</th>
                <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"investment")}>Investment</th>
                <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"price")}>Price</th>
                <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"price")}>Quantity</th>
                <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"holding")}>Holding</th>
                <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"value")}>Value</th>
                <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"delta")}>Delta</th>
                <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this,"margin")}>Margin</th>
              </tr>
             {
                positions.map(p => (
                <tr>
                  <td> {idx += 1} </td>
                  <td className="falcon_name"> 
                    <Link to="/prism/falcon_orders" state={{account: account, falcon_name: p.falcon, ticker: p.ticker, start_date: startDate, end_date: endDate}}>{p.falcon}</Link>
                  </td>
                  <td className="falcon_name"> 
                    <Link to="/prism/pending" state={{account:account, falcon_name:p.falcon, ticker:p.ticker}}> {p.ticker} </Link>
                  </td>
                  <td>$ {p.investment ? p.investment.toFixed(2) : 0}</td>
                  <td>$ {p.price? p.price.toFixed(4) : 0}</td>
                  <td>{p.quantity ? p.quantity.toFixed(2) : 0}</td>
                  <td>$ {p.holding ? p.holding.toFixed(2): 0}</td>
                  <td>$ {p.value? p.value.toFixed(2) : 0}</td>
                  <td style={{color:p.delta >=0 ? "green" : "red"}}>$ {p.delta? p.delta.toFixed(2) : 0}</td>
                  <td style={{color:p.margin >=0 ? "green" : "red"}}>{p.margin? p.margin.toFixed(2) : 0} %</td>
                </tr>))
            }
            </table>
            </div> 
        ) : ""}

        </>
    )
}

const PositionSummary = (props) => {
    return (
        <>
        <table style={{width:"100%"}}>
        <tr>
        <th>Investment</th>
        <th>Max Holding</th>
        <th>Holding</th>
        <th>Value</th>
        <th>Delta</th>
        <th>Margin</th>
        </tr>
        <tr>
        <td>$ {props.summary.investment ? props.summary.investment.toFixed(2): 0}</td>
        <td>$ {props.summary.max_holding? props.summary.max_holding.toFixed(2): 0}</td>
        <td>$ {props.summary.holding? props.summary.holding.toFixed(2): 0}</td>
        <td>$ {props.summary.value? props.summary.value.toFixed(2): 0}</td>
        <td style={{color:props.summary.delta >=0 ? "green" : "red"}}>$ {props.summary.delta? props.summary.delta.toFixed(2): 0}</td>
        <td style={{color:props.summary.margin >=0 ? "green" : "red"}}>{props.summary.margin? props.summary.margin.toFixed(2) : 0}%</td>
        </tr>
        </table>
        </>
    )
}


export default Position

