import React, { useState, useRef } from 'react';
const EditableCell = ({ cellkey, field, value, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const [previousValue, setPreviousValue] = useState(value);
  const inputRef = useRef(null);

  const handleCellClick = () => {
    setIsEditing(true);
    setTimeout(() => inputRef.current && inputRef.current.focus(), 0); // Focus on the input field when it's rendered
  };

  const handleInputChange = (e) => {
    setCurrentValue(e.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    setCurrentValue(previousValue); // Revert to previous value if no save
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setIsEditing(false);
      setPreviousValue(currentValue);
      onSave(cellkey, field, currentValue); // Trigger callback with the new value
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setCurrentValue(previousValue); // Revert to previous value on escape
    }
  };

  return (
    <td onClick={handleCellClick} style={{cursor: 'pointer' }}>
      {isEditing ? (
        <input
          ref={inputRef}
          type="text"
          value={currentValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          style={{ padding: '5px', border:'1px solid #eee', width: '100%', boxSizing: 'border-box' }}
        />
      ) : (
        <span>{currentValue}</span>
      )}
    </td>
  );
};

export default EditableCell;
