// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item {
  font-size: 1rem;
  list-style-type: none;
  padding: 10px 0px;
  border-top: 1px solid #eaeaea;
}

.checkbox {
  margin-right: 15px;
}

.item button {
  font-size: 13px;
  background: #f1f3f4;
  border: none;
  cursor: pointer;
  float: right;
  outline: none;
}

.textInput {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
}
`, "",{"version":3,"sources":["webpack://./src/css/todo_item.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,qBAAqB;EACrB,iBAAiB;EACjB,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,aAAa;AACf;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".item {\n  font-size: 1rem;\n  list-style-type: none;\n  padding: 10px 0px;\n  border-top: 1px solid #eaeaea;\n}\n\n.checkbox {\n  margin-right: 15px;\n}\n\n.item button {\n  font-size: 13px;\n  background: #f1f3f4;\n  border: none;\n  cursor: pointer;\n  float: right;\n  outline: none;\n}\n\n.textInput {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
