import React,{ useState, useEffect } from 'react';
import {Link, useLocation} from "react-router-dom"
import ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios'

import "./css/prism.css"
import Header from "../header"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
//import TradingViewWidget, { Themes, IntervalTypes, BarStyles } from 'react-tradingview-widget';

const FalconOrders = () => {
    const [orders, setOrders] = useState([]) 
    const [sales, setSales] = useState({}) 
    const location = useLocation()
    const [startDate, setStartDate] = useState(location.state['start_date'])
    const [endDate, setEndDate] = useState(location.state['end_date'])
    const falcon_name = location.state['falcon_name']
    const account = location.state['account']
    const trading_ticker = location.state['ticker']
    const navigate = useNavigate();

    const convertTime = (timestamp) => {
      let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(timestamp * 1000)
      return date
    }

    const getGap = (o) => {
        if (o == null || o.order_id == null) {
            return "--"
        }
        if (!(o.order_id in sales)) {
            return '**'
        }
        let st = o.timestamp
        let bt = sales[o.order_id].buy_timestamp
        let gap = st - bt
        if(gap > 3600 * 24) {
            return '' + (gap/(3600 * 24)).toFixed(0) + " days"
        }
        else if (gap > 3600) {
            return '' + (gap/(3600)).toFixed(0) + " hrs"
        }
        else if (gap > 60) {
            return '' + (gap/(60)).toFixed(0) + " mins"
        }
        return '' + gap.toFixed(0) + " secs"
    }

    const buyAndSell = (order_id) => {
        for(let sid in sales) {
            if(sales[sid].buy_order_id == order_id) {
                return true
            }
        }
        return false
    }

    useEffect(() => {
        startDate.setHours(0, 0, 0)
        let start_time = parseInt(startDate.getTime()/1000)
        endDate.setHours(0, 0, 0)
        let end_time = parseInt(endDate.getTime()/1000) + 24 * 60 * 60
        let sales = {}
        let request = "https://walmates.com/api/prism/falcon_orders/get?user=" + account.name + "&falcon=" + falcon_name + "&start_time=" + start_time + "&end_time=" + end_time
        console.log("Falcon Orders Request:", request)
        let token = localStorage.getItem('walmates-token')
        axios.get(request, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            let data = result.data
            setOrders(data['orders'])
            data['sales'].map(sale => {
                sales[sale.sell_order_id] = sale
            })
            setSales(sales)
        })
        .catch(err => {
          console.error(err)
          navigate("/login", {replace:true});
        });
    }, [startDate, endDate]);

    let idx = 0
    return (
        <>
        <Header/>
        <div className="falcon_row">
        <div className="search_bar">
        <div className="search_item">
        <DatePicker selected={startDate} onChange={(date:Date)=>setStartDate(date)}/>
        </div>
        <div className="search_item">
        <DatePicker selected={endDate} onChange={(date:Date)=>setEndDate(date)}/>
        </div>
        </div>
        </div>

        <div className="spectrum_table">
        <table>
          <tr>
            <th>No</th>
            <th>Time</th>
            <th>Side</th>
            <th>Price</th>
            <th>Value</th>
            <th>Time</th>
            <th>Gap</th>
            <th>Price</th>
            <th>Value</th>
            <th>Profit</th>
            <th>Profit %</th>
          </tr>

         {
         orders.map(o => (
            <tr style={{background:o.side=="sell"? "#eeffee" : buyAndSell(o.order_id) ? "#ffffee" : "white"}}>
              <td> {idx += 1} </td>
              <td> {convertTime(o.timestamp)}</td>
              <td> {o.side}</td>
              <td> {o.price.toFixed(4)}</td>
              <td> {o.value.toFixed(2)}</td>
              <td> {o.side == "sell" && o.order_id in sales ? convertTime(sales[o.order_id].buy_timestamp) : ''}</td>
              <td> {o.side == "sell" ? getGap(o) : ''}</td>
              <td> {o.side == "sell" && o.order_id in sales ? sales[o.order_id].buy_price.toFixed(4) : ''}</td>
              <td> {o.side == "sell" && o.order_id in sales ? sales[o.order_id].buy_value.toFixed(4) : ''}</td>
              <td> {o.side == "sell" && o.order_id in sales ? sales[o.order_id].profit.toFixed(4) : ''}</td>
              <td> {o.side == "sell" && o.order_id in sales? (sales[o.order_id].profit/sales[o.order_id].buy_value * 100).toFixed(2) + ' %': ''}</td>
            </tr>
         ))}
        </table>
        </div> 
        </>
    )
}
export default FalconOrders
