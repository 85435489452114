import React,{ useState, useEffect } from 'react';
import {Link, useLocation} from "react-router-dom"
import ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios'

import "./css/prism.css"
import Header from "../header"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"

const Orders = () => {
    const [orders, setOrders] = useState([]) 
    const [order_count, setOrderCount] = useState(0)
    const [buy_count, setBuyCount] = useState(0) 
    const [sell_count, setSellCount] = useState(0) 
    const [buy_value, setBuyValue] = useState(0) 
    const [sell_value, setSellValue] = useState(0) 
    const location = useLocation()
    const [startDate, setStartDate] = useState(location.state['start_date'])
    const [endDate, setEndDate] = useState(location.state['end_date'])
    const account = location.state['account']
    const trading_ticker = location.state['ticker']

    const [filter, setFilter] = useState("")
    const [sort_column, setSortColumn] = useState('turnover')
    const [sort_order, setSortOrder] = useState(true)
    const navigate = useNavigate();

    const convertTime = (timestamp) => {
      let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(timestamp * 1000)
      return date
    }

    useEffect(()=> {
        let data = orders.sort((a, b) => { 
            if (sort_order == true) {
                return a[sort_column] > b[sort_column] ? -1 : 1
            } else {
                return a[sort_column] > b[sort_column] ? 1 : -1
            }
        });
        setOrders(data)
    }, [sort_column, sort_order]);

    const onClickHandler = (col) => {
        setSortOrder(!sort_order)
        setSortColumn(col)
    }

    useEffect(() => {
        startDate.setHours(0, 0, 0)
        let start_time = parseInt(startDate.getTime()/1000)
        endDate.setHours(0, 0, 0)
        let end_time = parseInt(endDate.getTime()/1000) + 24 * 60 * 60
        let request = "https://walmates.com/api/prism/order/get?user=" + account.name + "&from_time=" + start_time + "&till_time=" + end_time
        console.log("Order Request:", request)
        let total_buy = 0
        let total_buy_count = 0
        let total_sell = 0
        let total_sell_count = 0
        let oc = 0
        let token = localStorage.getItem('walmates-token')
        axios.get(request, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(result => {
            let data = result.data
            data.map( o => 
            { 
                if (o.state == "canceled") {
                    o.quantity = 0;
                    o.filled_quantity = 0;
                }
                o.visible = filter == "" || o.falcon.toUpperCase().includes(filter) || o.ticker.toUpperCase().includes(filter) || o.side.toUpperCase().includes(filter) || o.state.toUpperCase().includes(filter)
                o.value = o.quantity * o.price
                if (o.visible) {
                    oc += 1      
                    if(o.side == "buy") {
                        total_buy_count += 1
                        total_buy += o.filled_price * o.filled_quantity
                    }
                    else if(o.side == "sell") {
                        total_sell_count += 1
                        total_sell += o.filled_price * o.filled_quantity
                    }
                }
            })
            setOrders(data)
            setBuyCount(total_buy_count)
            setBuyValue(total_buy)
            setSellCount(total_sell_count)
            setSellValue(total_sell)
            setOrderCount(oc)
        })
        .catch(err => {
          console.error(err)
          navigate("/login", {replace:true});
        });
    }, [startDate, endDate, filter, sort_column]);

    let idx = 0
    return (
        <>
        <Header/>
        <div className="search_bar">
        <div className="search_item">
        <DatePicker selected={startDate} onChange={(date:Date)=>setStartDate(date)}/>
        </div>
        <div className="search_item">
        <DatePicker selected={endDate} onChange={(date:Date)=>setEndDate(date)}/>
        </div>
        </div>
        <div className="spectrum_table">
        <h2 className="falcon_row"> Orders</h2>
        <td style={{width:"30em", marginBottom: "2px"}}> 
            <input
            type="text"
            className="falcon_text"
            placeholder="Search:"
            onChange={(e) => {
                setFilter(e.target.value.trim().toUpperCase())
            }}
            />
        </td>
        <table>
        <tr>
        <th>Count</th>
        <th>Buys</th>
        <th>Sells</th>
        <th>Buy Value</th>
        <th>Sell Value</th>
        <th>Change</th>
        </tr>
        <tr>
        <td>{order_count}</td>
        <td>{buy_count}</td>
        <td>{sell_count}</td>
        <td>{buy_value.toFixed(2)}</td>
        <td>{sell_value.toFixed(2)}</td>
        <td>{(sell_value - buy_value).toFixed(2)}</td>
        </tr>
        </table>
        </div>

        <div className="spectrum_table">
        <table>
          <tr>
            <th>No</th>
            <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this, "timestamp")}>Time</th>
            <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this, "falcon")}>Falcon</th>
            <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this, "ticker")}>Ticker</th>
            <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this, "side")}>Side</th>
            <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this, "state")}>State</th>
            <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this, "price")}>Price</th>
            <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this, "quantity")}>Quantity</th>
            <th style={{cursor:"pointer"}} onClick={onClickHandler.bind(this, "value")}>Value</th>
          </tr>

        { orders.map(o => (o.visible) ?
            <tr>
              <td> {idx += 1} </td>
              <td> {convertTime(o.timestamp)}</td>
              <td> {o.falcon ? o.falcon : ""}</td>
              <td> {o.ticker ? o.ticker : ""}</td>
              <td> {o.side ? o.side : ""}</td>
              <td> {o.state ? o.state : ""}</td>
              <td> {o.price ? o.price.toFixed(4) : 0}</td>
              <td> {o.quantity ? o.quantity.toFixed(2) : 0}</td>
              <td> {o.value ? o.value.toFixed(2) : 0}</td>
            </tr>
            : null
         )}
        </table>
        </div> 
        </>
    )
}
export default Orders
