import React,{ useState, useEffect } from 'react';
import {Link, useLocation} from "react-router-dom"
import ReactDOM from 'react-dom';
import Tooltip from "@material-ui/core/Tooltip";

import "./css/prism.css"
import Header from "../header"

const Spectrum = () => {
    const [spectrum, setSpectrum] = useState([]) 
    const location = useLocation()
    const account = location.state['account']
    const falcon_name = location.state['falcon_name']
    const trading_ticker = location.state['ticker'].replace('-', '')

    useEffect(() => {
        console.log("get spectrum ")
        fetch("https://walmates.com/api/prism/spectrum/get?user=" + account.name + "&falcon=" + falcon_name)
        .then(result => result.json())
        .then(data => {
            console.log("received response from get_spectrum", data)
            setSpectrum([data])
        });
    }, []);

    let idx = 0
    return (
        <>
        <Header/>
        <div className="spectrum_table">
        {
            spectrum.map(s => (
                <SpectrumSummary 
                    account={account}
                    key={idx += 1} 
                    spectrum={s} 
                    falcon_name={falcon_name}
                    ticker={trading_ticker}
            />))
        }
        </div>

        {
            spectrum.map(s => (
                <SpectrumView key={idx +=1 } spectrum={s}/>))
        }
        </>
    )
}
export default Spectrum

const SpectrumSummary = (props) => {

    return (
        <>
            <table>
                <tr>
                    <th>Falcon</th>
                    <th>Ticker</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Invested</th>
                    <th>Allocaton</th>
                    <th>Filled</th>
                    <th>Value</th>
                    <th>Pending</th>
                    <th>Change</th>
                    <th>Change%</th>
                </tr>

                <tr>
                    <td className="falcon_name">
                        <Link to="/prism/pending" state={{account:props.account, falcon_name:props.falcon_name, ticker: props.ticker}}> {props.falcon_name} </Link>
                    </td>
                    <td className="falcon_name"> 
                        <Link to="/prism/heat" state={{account:props.account, falcon_name:props.falcon_name, ticker: props.ticker}}> {props.ticker} </Link>
                    </td>
                    <td>{props.spectrum.price ? props.spectrum.price.toFixed(4): 0}</td>
                    <td>{props.spectrum.quantity ? props.spectrum.quantity.toFixed(4): 0}</td>
                    <td>{props.spectrum.investment ? props.spectrum.investment.toFixed(4) : 0}</td>
                    <td>{props.spectrum.allocation ? props.spectrum.allocation.toFixed(0) : 0}</td>
                    <td>{props.spectrum.filled ? (props.spectrum.filled).toFixed(4) : 0}</td>
                    <td>{props.spectrum.value ? props.spectrum.value.toFixed(4) : 0}</td>
                    <td>{props.spectrum.pending ? props.spectrum.pending.toFixed(4) : 0}</td>
                    <td style={
                        {
                            color:props.spectrum.value < props.spectrum.investment ? "red" : "green"}} >{(props.spectrum.value - props.spectrum.filled).toFixed(4)
                        }
                    </td>
                    <td style={
                        {
                            color:props.spectrum.value < props.spectrum.investment ? "red" : "green"}} >{((props.spectrum.value - props.spectrum.filled)/props.spectrum.filled * 100).toFixed(2)} % </td>
                </tr>
            </table>
        </>
    )
}

const SpectrumView = (props) => {
    let idx = 0
    return (
        <>
        {
            props.spectrum.layers.map(l => (
                    <LayerView layer_id={idx} key={idx+=1} price={props.spectrum.price} layer={l} spectrum={props.spectrum}/>
            ))
        }
        </>
    )
}

const LayerView = (props) => {
    const [invest, setInvest] = useState(0) 
    const [value, setValue] = useState(0) 
    const [use_count, setUseCount] = useState(0)
    useEffect(() => {
        let i = 0;
        let v = 0;
        let uc = 0;
        props.layer.cells.map(c => {
            if (c.is_free == false) {
                i += c.filled 
                v += c.value
                uc += 1
            }
        })
        setInvest(i);
        setValue(v);
        setUseCount(uc)

    }, []);

    let idx = 0
    return (
        <>
        <div className="spectrum_table">
        <table >
        <tr>
        <th>Level</th>
        <th>Invest</th>
        <th>Value</th>
        <th>[+/-]</th>
        <th>High</th>
        <th>Low</th>
        <th>Gap</th>
        <th>Cells</th>
        </tr>
        <tr>
        <td>{props.layer_id + 1} </td>
        <td>$ {invest.toFixed(2)} </td>
        <td>$ {value.toFixed(2)} </td>
        <td style={{color: (value>=invest)?"green" : "red"}}>{ (invest > 0) ? ((value-invest)/invest *100).toFixed(2) + "%" : ""} </td>
        <td>$ {props.layer.high_watermark.toFixed(2)} </td>
        <td>$ {props.layer.low_watermark.toFixed(2)} </td>
        <td>$ {props.layer.gap.toFixed(4)} </td>
        <td>{use_count} / {props.layer.box_count} </td>
        </tr>
        </table>
        <div className="layer">
        {
            props.layer.cells.map(c => (
                <CellView cell_id={idx} key={idx+=1} price={props.price} cell={c} spectrum={props.spectrum}/>
            ))
        }
        </div>
        </div>
        </>
    )
}

const CellView = (props) => {

    const [cellStyle, setCellStyle] = useState("free_cell") 
    const [cellWrapperStyle, setCellWrapperStyle] = useState("cell_wrapper")

    useEffect(() => {
        let startDate = new Date()
        startDate.setHours(0, 0, 0)
        let start_time = parseInt(startDate.getTime()/1000)
        if (props.cell.end_price <= props.spectrum.high && props.cell.start_price >= props.spectrum.low) {
            setCellWrapperStyle("cell_wrapper_inrange")
        }
        if (!props.cell.is_free) {
            if (props.cell.buy_price <= props.price) {
                setCellStyle("profit_cell")
            } else {
                setCellStyle("loss_cell")
            }
            if(props.cell.timestamp > start_time) {
                setCellWrapperStyle("cell_wrapper_today");
            }
        } 
        if(props.price >= props.cell.start_price && props.price < props.cell.end_price) {
            setCellWrapperStyle("cell_wrapper_current");
        }
    }, []);


    return( 
        <>
        <Tooltip  title={
            "[" + props.cell.end_price.toFixed(4) + 
            ", " + 
            props.cell.start_price.toFixed(4) + "]" + 
            ((props.cell.buy_price != null) ? " Buy:" + props.cell.buy_price : "")
        }>
        <div className={cellWrapperStyle}>
        <div data-tip="hello" className={cellStyle}></div>
        </div>
        </Tooltip>
        </>
    )
}

