import React,{ Component, useState, useEffect, useRef } from 'react'
import "./css/tag.css"

export const Tag = ({ label, onSelect, selected, mode }) => {
  return (
    <div className="tag">
      <input
        type= {mode}
        id={label}
        checked={selected}
        onChange={() => onSelect(label)}
      />
      <label htmlFor={label}>{label}</label>
    </div>
  );
};
