import React,{ Component, useState, useEffect, useRef } from 'react'
export const FavoriteHeart = ({item, isFavorite, onFavoriteChange}) => {
  const [isSelected, setIsSelected] = useState();

  useEffect(() => {
      setIsSelected(isFavorite)
  }, [isFavorite])

  const handleFavoriteChange = () => {
      let selected = !isSelected
      setIsSelected(selected);  // Toggle the `favorite` state
      onFavoriteChange(selected)
  }

  return (
        <div onClick={handleFavoriteChange} style={{border:"1px solid #eee", cursor:"pointer", paddingLeft:"5px", borderRadius:"5px"}}>
        <svg
        className="heart-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        fill= {isSelected ? "red" : "gray"}  // Set fill to 'currentColor' to inherit color from parent
        style={{ cursor: 'pointer', marginTop: '3px', marginRight: '5px' }}
        >
        <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
        </svg>
        </div>
  );
}
